import { computed, ref } from "vue";

export const useTableFilter = () => {
  const filters = ref({});

  const addFilter = (filterKey, key, value) => {
    if (filters.value[filterKey]?.[key]) return;
    filters.value = {
      ...filters.value,
      [filterKey]: { ...filters.value[filterKey], [key]: value },
    };
  };

  const removeFilter = (filterKey, key) => {
    const copyFilters = { ...filters.value[filterKey] };
    delete copyFilters[key];
    filters.value = { ...filters.value, [filterKey]: copyFilters };
  };

  const filtersConverted = computed(() =>
    Object.fromEntries(
      Object.entries(filters.value).map(([key, value]) => {
        const array = [...Object.keys(value || {})];
        return [key, { array, string: array.join(",") }];
      }),
    ),
  );

  const filtersLength = computed(() => Object.values(filters.value).flatMap(Object.values).length);

  return { filters, addFilter, removeFilter, filtersLength, filtersConverted };
};
