import store from "@/store";
import * as Sentry from "@sentry/browser";
import { reactive, computed, ref } from "vue";
import { useToastsStore } from "./toastsStore";

export const exceptionWrapper = async (
  f = () => null,
  { loading, error, toast, throw: throwError },
) => {
  try {
    loading && (loading.value = true);
    error && (error.value = false);
    return await f();
  } catch (error) {
    Sentry.captureException(error);
    toast && useToastsStore().create({ type: "error", body: error.message });
    error && (error.value = true);
    if (throwError) throw error;
  } finally {
    loading && (loading.value = false);
  }
};

export const catchable = async ({
  t = () => null,
  c = () => null,
  f = () => null,
  loadable = false,
  throwable = false,
}) => {
  try {
    loadable && store.dispatch("setLoading", true);
    return await t();
  } catch (error) {
    store.dispatch("sendError", error);
    Sentry.captureException(error);
    await c(error);
    if (throwable) throw error;
    return null;
  } finally {
    loadable && store.dispatch("setLoading", false);
    await f();
  }
};

export const useTableData = ({ perPageInit = 10 } = { perPageInit: 10 }) => {
  const page = ref(0);
  const perPage = ref(perPageInit);
  const totalCount = ref(perPageInit);
  const totalPages = ref(1);
  const table = reactive({
    page,
    perPage,
    totalCount,
    totalPages,
    showing: computed(() => Math.min(page.value * perPage.value, totalCount.value)),
    loadMore: computed(() =>
      Math.max(Math.min(totalCount.value - page.value * perPage.value, perPage.value), 0),
    ),
  });

  const resetPage = () => {
    page.value = 0;
    perPage.value = perPageInit;
    totalCount.value = perPageInit;
    totalPages.value = 1;
  };

  return { table, resetPage };
};
