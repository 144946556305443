var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promo-design-group"},[_c('div',{staticClass:"promo-timer-setting"},[_c('div',{staticClass:"div-flex-layout",staticStyle:{"margin-bottom":"15px"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Auto close in seconds","error-messages":_vm.errors.first('close-seconds'),"name":"close-seconds","hide-details":"","placeholder":"e.g.: 20","background-color":"#ffffff"},model:{value:(_vm.actions.rf_settings_close_seconds),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_close_seconds", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"actions.rf_settings_close_seconds"}}),(
          (_vm.model.path_type === 'retention_modal' ||
            _vm.model.path_type === 'video' ||
            _vm.model.path_type === 'interstitial' ||
            _vm.model.path_type === 'widget') &&
          !_vm.hideAutoCloseText
        )?_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Auto close text","error-messages":_vm.errors.first('close-seconds'),"disabled":_vm.currentTranslations.hasOwnProperty('rf_settings_close_seconds_text'),"name":"close-seconds","hide-details":"","placeholder":"e.g.: 20","background-color":"#ffffff"},model:{value:(
          _vm.currentTranslations.hasOwnProperty('rf_settings_close_seconds_text')
            ? _vm.previewActions.rf_settings_close_seconds_text
            : _vm.actions.rf_settings_close_seconds_text
        ),callback:function ($$v) {_vm.$set(_vm.currentTranslations.hasOwnProperty('rf_settings_close_seconds_text')
            ? _vm.previewActions.rf_settings_close_seconds_text
            : _vm.actions, "rf_settings_close_seconds_text", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n          currentTranslations.hasOwnProperty('rf_settings_close_seconds_text')\n            ? previewActions.rf_settings_close_seconds_text\n            : actions.rf_settings_close_seconds_text\n        "}}):_vm._e()],1),_c('div',{staticClass:"div-flex-layout"},[(!_vm.displayOff)?_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Timer font size","name":"close-seconds","hide-details":"","placeholder":"e.g.: 14px","background-color":"#ffffff"},model:{value:(_vm.actions.rf_settings_timer_font_size),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_timer_font_size", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"actions.rf_settings_timer_font_size"}}):_vm._e(),(!_vm.displayOff)?_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Timer font color","name":"close-seconds","hide-details":"","placeholder":"e.g.: #ffffff","background-color":"#ffffff"},model:{value:(_vm.actions.rf_settings_timer_font_color),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_timer_font_color", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"actions.rf_settings_timer_font_color"}}):_vm._e()],1),(_vm.actions.rf_settings_close_seconds)?_c('RfPromoInteractionInterval',{attrs:{"interactionType":"timeout","action":_vm.actions.rf_settings_timeout_interval},on:{"setIntervalAction":_vm.setIntervalAction}}):_vm._e(),(_vm.model.path_type !== 'text')?_c('div',{staticClass:"div-flex-layout",staticStyle:{"margin-bottom":"15px"}},[_c('v-checkbox',{attrs:{"value":"true","label":"Hide timer text","hide-details":""},model:{value:(_vm.actions.rf_settings_hide_timer_text),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_hide_timer_text", $$v)},expression:"actions.rf_settings_hide_timer_text"}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }