<template>
  <RfSimpleCard v-if="isSuperAdminRoleMixin">
    <RfAdminLite></RfAdminLite>
    <RfSimpleCard title="Admin">
      <div class="px-7">
        <div class="mt-7 flex flex-row flex-nowrap">
          <div class="flex-1">
            <h4>Feature Set</h4>
            <div>
              <v-checkbox
                v-model="hasMetricsL2Cache"
                :value="true"
                label="Metrics L2 Cache"
                hide-details
              />
              <v-checkbox
                v-model="hasCohortSampleData"
                :value="true"
                label="Cohort Sample Data"
                hide-details
              />
              <v-checkbox v-model="hasSequences" :value="true" label="Guides" hide-details />
              <v-checkbox v-model="hasPipelines" :value="true" label="Pipelines" hide-details />
              <v-checkbox v-model="hasLiveV2" :value="true" label="Live V2" hide-details />

              <v-checkbox
                v-model="hasInvisMeta"
                :value="true"
                label="Invisible Meta"
                hide-details
              />
              <v-checkbox
                v-model="flags.banner_for_non_web_devices"
                :value="true"
                label="Enable 'Bottom Banner' for non-web devices"
                hide-details
              />
              <v-checkbox
                v-model="flags.mparticle_event_export"
                :value="true"
                label="MParticle event export"
                hide-details
              />
              <v-checkbox
                v-model="flags.elastic_search_metrics"
                :value="true"
                label="Elastic search metrics"
                hide-details
              />
              <v-checkbox
                v-model="flags.elastic_dashboard_metrics"
                :value="true"
                label="Elastic dashboard metrics"
                hide-details
              />
              <v-checkbox
                v-model="flags.one_year_metrics"
                :value="true"
                label="1 Year reporting settings"
                hide-details
              />
              <v-checkbox
                v-model="flags.iterable_sync_enabled"
                :value="true"
                label="Iterable event sync"
                hide-details
              />
              <div style="margin-top: 16px">
                <RfTextInput
                  title="Traits whitelist"
                  v-model="newWhiteListItem"
                  outlined
                  dense
                  class="my-5"
                  hide-details
                  style="width: 50%"
                />
                <v-btn @click="addToTraitWhitelist"> Add Trait </v-btn>
                <v-simple-table v-if="flags.traits_whitelist" :key="previewKey">
                  <thead>
                    <tr>
                      <th>Trait</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(trait, index) in flags.traits_whitelist || []" :key="index">
                      <td>{{ trait }}</td>
                      <td>
                        <v-icon
                          size="18"
                          style="margin-left: 10px"
                          @click="deleteTraitWhitelistItem(index)"
                          class="delete-connector-action-button"
                          >clear
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
              <div style="margin-top: 16px">
                <h4>Trait Aliases</h4>
                <div>
                  <p :key="previewKey">{{ flags.trait_aliases }}</p>
                  <v-btn @click="openEditTraitAliases">Edit Aliases</v-btn>
                </div>
                <v-dialog scrollable persistent v-model="editTraitsDialog" width="550">
                  <v-card>
                    <v-card-title class="headline">Trait Aliases</v-card-title>
                    <v-card-text>
                      <div class="trigger-args-container">
                        <div class="trigger-args-add-btn">
                          <v-btn
                            icon
                            small
                            @click="addTraitsrow"
                            style="margin-top: 4px"
                            color="primary"
                          >
                            <v-icon size="22">add</v-icon> Add trait alias</v-btn
                          >
                        </div>
                        <hr class="trigger-args-divider" />
                        <div
                          v-for="(pair, index) in traitsPairs"
                          :key="index"
                          class="trigger-args-row"
                        >
                          <RfTextInput
                            outlined
                            dense
                            hide-details
                            v-model="traitsPairs[index].key"
                            placeholder="Key"
                            background-color="#ffffff"
                            style="margin-right: 8px"
                          />
                          <RfTextInput
                            outlined
                            dense
                            hide-details
                            v-model="traitsPairs[index].value"
                            placeholder="Value"
                            background-color="#ffffff"
                          />
                          <v-btn icon small @click="deleteTraitRow(index)" style="padding-top: 3%">
                            <v-icon size="22">close</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-card-text>
                    <v-card-actions class="modal-card-actions-div">
                      <v-btn
                        large
                        depressed
                        style="width: 100px"
                        @click="closeEditTraitAliases"
                        class="cancel-btn"
                        outlined
                        >Cancel</v-btn
                      >
                      <v-btn
                        large
                        depressed
                        @click="submitTraitAliases"
                        color="success"
                        style="width: 150px"
                        >Done</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <h4>Other Settings</h4>
            <v-checkbox
              v-model="flags.skip_live_ingest"
              label="Skip Live Ingest"
              :value="true"
              hide-details
            />
            <v-checkbox
              hide-details
              v-model="flags.skip_ingest"
              label="Skip Ingest"
              :value="true"
            />
            <v-checkbox
              v-model="flags.fraud_check"
              label="Enable Fraud Detection"
              :value="true"
              hide-details
            />
            <v-checkbox
              v-model="flags.geo_lookup"
              label="Enable Geo Lookup"
              :value="true"
              hide-details
            />
            <v-checkbox
              v-model="flags.device_lookup"
              label="Enable Device Lookup"
              :value="true"
              hide-details
            />
            <v-checkbox
              v-model="flags.cache_warmer"
              label="Cache Warmer"
              :value="false"
              hide-details
            />
            <v-checkbox v-model="flags.app_warmer" label="App Warmer" :value="false" hide-details />
            <v-checkbox
              v-model="flags.client_extra_logging"
              label="Client JS Extra Logging"
              :value="false"
              hide-details
            />
            <v-checkbox
              v-model="flags.force_single_ingest"
              label="Single Ingest (Demo use only)"
              :value="false"
              hide-details
              v-if="currApp.is_internal"
            />
            <v-checkbox
              v-model="flags.email_path_enabled"
              label="Email Promo Flag"
              :value="true"
              hide-details
            />
            <v-checkbox
              v-model="flags.custom_devices"
              label="Custom Devices"
              :value="true"
              hide-details
            />
            <v-checkbox
              v-model="pathFilter.users.is_limited"
              label="Users Limits"
              :value="true"
              hide-details
            />
            <v-checkbox v-model="flags.device_api_implementation" label="Device API" hide-details />
            <v-checkbox
              v-model="flags.custom_devices_js_sdk"
              label="Custom Devices Allow JS SDK Options"
              hide-details
            />
            <v-checkbox
              v-model="flags.new_android_editor"
              label="New Android Prompt Editor"
              hide-details
            />
            <v-checkbox
              v-model="flags.legacy_apple_editor"
              label="Legacy Apple Prompt Editor"
              hide-details
            />
            <v-checkbox
              v-model="flags.scheduled_push"
              label="Enable Push Notification Prompt Type"
              hide-details
            />
            <v-checkbox
              v-model="flags.ai_tool"
              label="Enable AI Mode Preview (Super Admin Only)"
              hide-details
            />
            <v-checkbox
              v-model="flags.rds_user_guide_data_enabled"
              label="Enable RDS for sequence data"
              hide-details
            />
            <v-checkbox
              v-model="flags.report_delta_performance"
              label="Enable daily high delta performance reporting"
              hide-details
            />
            <v-checkbox
              v-model="flags.report_expiring_paths"
              label="Enable daily expiring prompts reporting"
              hide-details
            />
            <v-checkbox
              v-model="flags.report_expiring_sequences"
              label="Enable daily expiring guides reporting"
              hide-details
            />
            <v-checkbox
              hide-details
              v-model="flags.static_brig_build"
              label="Static Brig Build"
              :value="false"
            />
            <v-checkbox
              hide-details
              v-model="flags.enable_tags_freq_cap"
              label="Enable tags frequency cap"
            />
            <div style="padding-top: 20px">
              <h4>Auto ingest</h4>
              <v-checkbox
                v-model="flags.ga_autoingest"
                label="Google Analytics"
                :value="false"
                hide-details
              />
              <v-checkbox
                v-model="flags.mixpanel_autoingest"
                label="Mixpanel"
                :value="false"
                hide-details
              />
              <v-checkbox
                v-model="flags.segment_autoingest"
                label="Segment"
                :value="false"
                hide-details
              />
            </div>
          </div>
        </div>
        <div class="mb-5 mt-12 flex flex-row flex-nowrap">
          <div class="flex-1 pr-5">
            <RfTextInput
              outlined
              dense
              title="Ping Frequency"
              v-model.trim="flags.ping_frequency"
            />
            <RfTextInput
              outlined
              dense
              title="Action Fetch Delay"
              v-model.trim="flags.action_delay"
            />
            <RfTextInput
              outlined
              dense
              title="Metrics Parallel Process Count"
              v-model.trim="flags.metrics_process_count"
            />
          </div>
          <div class="flex-1">
            <RfTextInput
              outlined
              dense
              title="Pulse Chart Refresh Seconds"
              v-model.trim="flags.pulse_charts_refresh_seconds"
            />
            <RfTextInput
              outlined
              dense
              title="Hard Limit Users"
              v-model.trim="pathFilter.users.hard_limit"
              v-if="pathFilter.users.is_limited"
            />
            <RfTextInput
              outlined
              dense
              title="Soft Limit Users"
              v-model.trim="pathFilter.users.soft_limit"
              v-if="pathFilter.users.is_limited"
            />
            <RfTextInput
              outlined
              dense
              title="AI Agent Guide Id"
              v-model.trim="flags.ai_agent_guide_id"
            />
          </div>
        </div>

        <div class="flex flex-wrap content-start items-center gap-4" v-if="pinpointAppExists">
          <span class="text-base font-medium text-black-3">Enable Push Channels:</span>
          <v-btn @click="enablePush('adm')"> <v-icon left>fab fa-amazon</v-icon> ADM </v-btn>
          <v-btn @click="enablePush('fcm')"> <v-icon left>mdi-firebase</v-icon> FCM </v-btn>
          <v-btn @click="enablePush('apns')"> <v-icon left>fab fa-apple</v-icon> APNS </v-btn>
          <v-btn @click="enablePush('sms')"> <v-icon left>fas fa-sms</v-icon> SMS </v-btn>
        </div>
        <div class="mt-10 flex flex-wrap content-start gap-4">
          <v-btn depressed color="accent" @click="triggerJsSdkPublish">
            <v-icon left>fab fa-js</v-icon>
            Publish JS SDK
          </v-btn>
          <v-btn depressed color="success" @click="triggerSfmcSync">
            <v-icon left>fab fa-salesforce</v-icon>
            Sync SFMC
          </v-btn>
          <v-btn depressed color="error" @click="triggerSfmcPurge">
            <v-icon left>fab fa-salesforce</v-icon>
            Purge SFMC
          </v-btn>
          <v-btn depressed color="success" @click="triggerImagewizUpdateCfDistro">
            <v-icon left>image</v-icon>
            Update Image Resizer
          </v-btn>
          <v-btn depressed color="success" @click="triggerImagewizUpdateCfDistroAll">
            <v-icon left>image</v-icon>
            Update Image Resizer All Apps
          </v-btn>

          <v-btn depressed color="success" @click="triggerInvalidateCache">
            <v-icon left>clear</v-icon>Clear App Cache
          </v-btn>
          <v-btn depressed color="info" @click="triggerCreatePinpointApp" v-if="!pinpointAppExists">
            <v-icon size="18" left>fas fa-thumbtack</v-icon>
            Create Pinpoint App
          </v-btn>
          <v-btn depressed color="secondary" @click="triggerSampleData">
            <v-icon left>fas fa-database</v-icon>
            Generate Sample Data
          </v-btn>
          <v-btn depressed color="info" @click="triggerCreatePipelines" :disabled="!hasPipelines">
            <v-icon left>fas fa-stream</v-icon>
            Create Default Pipelines
          </v-btn>
          <v-btn depressed color="info" @click="triggerCreateSequences">
            <v-icon left>fas fa-stream</v-icon>
            Create Default Guides
          </v-btn>
          <v-btn depressed color="accent" @click="submitEnableEmail" v-if="pinpointAppExists">
            <v-icon left>fab fa-js</v-icon>
            Enable Pinpoint Email Channel
          </v-btn>
          <v-dialog v-model="showDialog" persistent max-width="450">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="isSuperAdminRoleMixin"
                color="#cc0000"
                class="rf-settings-delete-btn"
                v-on="on"
                depressed
              >
                <v-icon color="#ffffff" left>delete</v-icon>
                <span style="color: #fff">Delete {{ currApp.name }}</span>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Are you sure you want to delete?</v-card-title>
              <v-card-text>
                Deleting an app removes all information about this app including segments,
                historical data, users and integrations. This cannot be undone.
              </v-card-text>
              <v-card-actions class="modal-card-actions-div">
                <v-btn class="cancel-btn px-7" outlined @click="showDialog = false">Cancel</v-btn>
                <v-btn color="error" class="px-7" @click="removeApp">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </RfSimpleCard>
  </RfSimpleCard>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import RoleMixin from "@/utils/RoleMixin";
import RfAdminLite from "./RfAdminLite.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import { useToastsStore } from "@/pinia/toastsStore";
import { cloneDeep } from "lodash-es";
import ApiApps from "@/apis/ApiApps";

export default {
  name: "RfAdmin",
  setup: () => ({ toastsStore: useToastsStore() }),
  props: ["model"],
  mixins: [RoleMixin],
  components: { RfSimpleCard, RfAdminLite, RfTextInput },
  data() {
    return {
      currApp: this.model,
      hasMetricsL2Cache: this.model.feature_set.indexOf("metrics_l2_cache") > -1,
      hasCohortSampleData: this.model.feature_set.indexOf("cohort_sample_data") > -1,
      showDialog: false,
      deletingApp: false,
      flags: cloneDeep(this.model.flags),
      pathFilter: cloneDeep(this.model.path_filter),
      hasSequences: this.model.feature_set.indexOf("sequences") > -1,
      hasPipelines: this.model.feature_set.indexOf("pipelines") > -1,
      hasLiveV2: this.model.feature_set.indexOf("live_v2") > -1,
      hasInvisMeta: this.model.feature_set.indexOf("invis_meta") > -1,
      newWhiteListItem: null,
      previewKey: 0,
      editTraitsDialog: null,
      traitsPairs: [],
    };
  },
  computed: {
    ...mapState({ apps: state => state.apps.apps }),
    pinpointAppExists() {
      return !!(this.currApp.providers.redfast && this.currApp.providers.redfast.pinpoint_app_id);
    },
  },
  methods: {
    ...mapActions([
      "publishSdk",
      "deleteApp",
      "invalidateCache",
      "createPinpointApp",
      "createSampleData",
      "syncSfmc",
      "purgeSfmc",
      "imagewizUpdateCfDistro",
      "createDefaultPipelines",
      "enablePinpointEmails",
      "setLoading",
      "createDefaultSequences",
    ]),
    removeApp() {
      this.deletingApp = true;
      this.deleteApp({
        appId: this.currApp.id,
        apps: [...this.apps],
      });
    },
    async triggerSfmcSync() {
      await this.syncSfmc({ appId: this.model.id });
      this.toastsStore.create({ type: "success", body: "Successfully Sync SFMC" });
    },
    async triggerSfmcPurge() {
      await this.purgeSfmc({ appId: this.model.id });
      this.toastsStore.create({ type: "success", body: "Successfully Purged SFMC" });
    },
    triggerJsSdkPublish() {
      this.publishSdk({ appId: this.model.id, type: "javascript" });
    },
    triggerImagewizUpdateCfDistro() {
      this.imagewizUpdateCfDistro({ appId: this.model.id, all: false });
    },
    triggerImagewizUpdateCfDistroAll() {
      this.imagewizUpdateCfDistro({ appId: this.model.id, all: true });
    },
    triggerInvalidateCache() {
      this.invalidateCache({ appId: this.model.id });
    },
    triggerCreatePinpointApp() {
      this.createPinpointApp({ appId: this.model.id });
    },
    triggerSampleData() {
      this.createSampleData({ appId: this.model.id });
    },
    triggerCreatePipelines() {
      this.createDefaultPipelines({ appId: this.model.id });
    },
    triggerCreateSequences() {
      this.createDefaultSequences({ appId: this.model.id });
    },
    submitEnableEmail() {
      this.enablePinpointEmails({ appId: this.model.id });
    },
    fillModel(model) {
      const featureSet = [];
      if (this.hasMetricsL2Cache) {
        featureSet.push("metrics_l2_cache");
      }
      if (this.hasCohortSampleData) {
        featureSet.push("cohort_sample_data");
      }
      if (this.hasSequences) {
        featureSet.push("sequences");
      }
      if (this.hasPipelines) {
        featureSet.push("pipelines");
      }
      if (this.hasLiveV2) {
        featureSet.push("live_v2");
      }
      if (this.hasInvisMeta) {
        featureSet.push("invis_meta");
      }
      model.feature_set = featureSet;

      [
        "fraud_check",
        "geo_lookup",
        "device_lookup",
        "skip_live_ingest",
        "skip_ingest",
        "cache_warmer",
        "app_warmer",
        "client_extra_logging",
        "force_single_ingest",
      ].forEach(flag => {
        if (!this.flags[flag]) {
          this.flags[flag] = false; // save false instead of null
        }
      });
      this.flags.ping_frequency = parseInt(this.flags.ping_frequency);
      this.flags.metrics_process_count = parseInt(this.flags.metrics_process_count);
      this.flags.action_delay = parseInt(this.flags.action_delay);
      model.path_filter = { ...model.path_filter, ...this.pathFilter };
      model.flags = { ...model.flags, ...this.flags };
    },
    deleteTraitWhitelistItem(index) {
      this.flags.traits_whitelist.splice(index, 1);
      this.previewKey++;
    },
    addToTraitWhitelist() {
      if (!this.flags.traits_whitelist) this.flags.traits_whitelist = [];
      this.flags.traits_whitelist.push(this.newWhiteListItem);
      this.newWhiteListItem = null;
    },
    openEditTraitAliases() {
      this.editTraitsDialog = true;
      this.traitsPairs = [];
      Object.keys(this.flags.trait_aliases || {}).forEach(key =>
        this.traitsPairs.push({ key, value: this.flags.trait_aliases[key] }),
      );
    },
    closeEditTraitAliases() {
      this.traitsPairs = [];
      this.editTraitsDialog = false;
    },
    submitTraitAliases() {
      const aliases = {};
      this.traitsPairs.forEach(pair => {
        if (pair.key && pair.key.length && pair.value && pair.value.length) {
          aliases[pair.key] = pair.value;
        }
      });
      this.flags.trait_aliases = aliases;
      this.previewKey++;
      this.closeEditTraitAliases();
    },
    addTraitsrow() {
      this.traitsPairs.push({ key: "", value: "" });
    },
    deleteTraitRow(index) {
      this.traitsPairs.splice(index, 1);
    },
    async enablePush(channel = "") {
      try {
        this.setLoading(true);
        await ApiApps.enablePushChannel(this.model.id, channel);
        this.toastsStore.create({
          type: "success",
          body: `Successfully enabled push channel "${channel.toUpperCase()}"`,
        });
      } catch (error) {
        this.toastsStore.create({ type: "error", body: error.message });
      } finally {
        this.setLoading(false);
      }
    },
  },
  watch: {
    "apps"(to, _) {
      if (this.deletingApp) {
        if (to.length > 0) {
          this.$router.push({ path: `/apps/${to[0].id}/dashboard` });
        } else {
          this.$router.push({ path: "/" });
        }
      }
    },
    "flags.rds_user_guide_data_write"(newValue) {
      // Disable reading whenever writing is disabled
      if (!newValue) {
        this.flags.rds_user_guide_data_read = false;
      }
    },
  },
};
</script>
