import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiTags {
  static getTags(appId) {
    if (!appId) throw Error("App id is missing");
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/tags`);
  }

  static createTag(appId, tag) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/tags`, {
      method: "POST",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(tag),
    });
  }

  static updateTag(appId, tag) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/tags/${tag.id}`, {
      method: "PUT",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(tag),
    });
  }

  static deleteTag(appId, tagId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/tags/${tagId}`, {
      method: "DELETE",
    });
  }

  static assignTagToPrompt(pathId, tagId) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/taggings`, {
      method: "POST",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({ tag: { id: tagId } }),
    });
  }

  static removeTagFromPrompt(pathId, tagId) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/taggings/${tagId}`, {
      method: "DELETE",
    });
  }

  static getPromptTags(pathId) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/taggings`);
  }
}
