<template>
  <div>
    <div v-if="props.filename && props.image" class="mt-1">
      <span>Current File: </span> <span>{{ props.filename }}</span>
    </div>
    <a :href="props.image" target="_blank" v-if="hasImageUrl"> Open Image in New Tab </a>
  </div>
</template>
<script setup>
import { computed } from "vue";

const props = defineProps({
  image: String,
  filename: String,
});

const hasImageUrl = computed(() => {
  return props.image?.startsWith("http");
});
</script>
