var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',{staticClass:"w-8"}),_c('th',{staticClass:"w-16 pr-0"},[_c(_setup.RfTableFilter,{attrs:{"hideIcon":"","activator":"Status","filters":{ statuses: { el: _setup.StatusesConstants, key: 'type', value: 'title' } },"selected":_vm.filters},on:{"selected":items => _vm.$emit('update:filters', { ..._vm.filters, ...items })}})],1),_c('th',[_c(_setup.RfTableFilter,{staticClass:"rf-header--guide-type",attrs:{"hideIcon":"","activator":"Guide type / creator","filters":{
        sequence_type: { el: _setup.GUIDE_TYPES, key: 'value', value: 'label', name: 'Guide type' },
        creator_ids: {
          el: _vm.users,
          key: 'id',
          value: _setup.getUserName,
          name: 'Creator',
        },
      },"selected":_vm.filters},on:{"selected":items => _vm.$emit('update:filters', { ..._vm.filters, ...items })}})],1),_c('th',{staticClass:"w-24 text-left 4xl:w-40"},[_c(_setup.RfTableFilter,{staticClass:"rf-header--segments flex-grow-0",attrs:{"hideIcon":"","activator":"Segments","filters":{ segments: { el: _vm.segments, key: 'id', value: 'name' } },"selected":_vm.filters},on:{"selected":items => _vm.$emit('update:filters', { ..._vm.filters, ...items })}})],1),_c('th',{staticClass:"w-20 4xl:w-32"},[_c(_setup.RfSortButton,{staticClass:"h-full w-full justify-start",attrs:{"filtered":_vm.sort.type === 'start_date' ? _vm.sort.value : null,"data-cy":"filters--guide-start"},on:{"click":value => _vm.$emit('update:sort', { type: 'start_date', value })}},[_vm._v(" Start ")])],1),_c('th',{staticClass:"w-20 4xl:w-32"},[_c(_setup.RfSortButton,{staticClass:"h-full w-full justify-start",attrs:{"filtered":_vm.sort.type === 'end_date' ? _vm.sort.value : null,"data-cy":"filters--guide-finish"},on:{"click":value => _vm.$emit('update:sort', { type: 'end_date', value })}},[_vm._v(" Finish ")])],1),_c('th',{staticClass:"w-24 bg-goal 4xl:w-32"},[_c(_setup.RfSortButton,{staticClass:"h-full w-full",attrs:{"filtered":_vm.sort.type === 'users' ? _vm.sort.value : null,"disabled":""},on:{"click":value => _vm.$emit('update:sort', { type: 'users', value })}},[_vm._v(" Users ")])],1),_c('th',{staticClass:"w-24 bg-goal 4xl:w-32"},[_c(_setup.RfSortButton,{attrs:{"filtered":_vm.sort.type === 'goal' ? _vm.sort.value : null,"disabled":""},on:{"click":value => _vm.$emit('update:sort', { type: 'goal', value })}},[_vm._v(" Goal ")])],1),_c('th',{staticClass:"w-28 bg-goal 4xl:w-32"},[_c(_setup.RfSortButton,{attrs:{"filtered":_vm.sort.type === 'conversion' ? _vm.sort.value : null,"disabled":""},on:{"click":value => _vm.$emit('update:sort', { type: 'conversion', value })}},[_vm._v(" Conversion ")])],1),_c('th',{staticClass:"w-10"})])
}
var staticRenderFns = []

export { render, staticRenderFns }