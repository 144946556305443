import { serializeQuery } from "@/utils/UrlUtils";
import { ApiBaseUrl, authenticatedFetch } from "./conduitApi";

export default class ConduitApiUsers {
  static resetGoal(appId, endUserId) {
    return authenticatedFetch(`${ApiBaseUrl}paths/goal_reset_all?id=${appId}`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "user-id": endUserId,
      },
    });
  }

  static ping(appId, endUserId, params = {}) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;

    return fetch(`${ApiBaseUrl}ping${query}`, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "user-id": endUserId,
        "Rf-App": appId,
      },
      method: "GET",
    }).then(res => {
      if (!res.ok) return null;

      const contentType = res.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return res.json();
      }
      return Promise.resolve({ body: res.text() });
    });
  }
}
