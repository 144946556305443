<template>
  <tr class="rf-row group/row relative">
    <td class="whitespace-nowrap pr-0" style="min-width: calc(24px + 1rem)">
      <RfCheckbox
        data-cy="guide-row--checkbox"
        :data-cy-value="selected"
        class="rf-row--checkbox py-1 opacity-0 group-hover/row:opacity-100"
        :class="{ 'opacity-100': selected }"
        :value="selected"
        @input="$emit(selected ? 'deselect' : 'select', guide.id)"
      />
    </td>
    <td class="whitespace-nowrap !px-5 5xl:!px-7">
      <span class="flex items-center justify-center gap-0.5">
        <RfMenu
          preset="promptStatus"
          class="!justify-center 5xl:!justify-start"
          :disabled="disabled || getGuideStatus.type === SimplifyStatusesMapConstants.limited.type"
        >
          <template #activator>
            <span class="inline-flex items-center gap-2">
              <RfStatus :status="getGuideStatus.type" />
              <span class="text-body hidden 5xl:inline-block">
                {{ StatusesConstants[getGuideStatus.type].title }}
              </span>
            </span>
          </template>
          <template #menu>
            <template v-for="status in StatusesSimplifiedConstants">
              <button
                v-if="
                  status.type === SimplifyStatusesMapConstants.ready.type
                    ? !guide.is_enabled && dayjs(guide.start_date).isAfter(dayjs())
                    : ![
                        SimplifyStatusesMapConstants[getGuideStatus.type].type,
                        SimplifyStatusesMapConstants.limited.type,
                      ].includes(status.type)
                "
                :key="status.type"
                class="flex w-full items-center gap-3 px-4 py-2 first:!pt-4 last:!pb-4"
                @click="$emit('status', status.type)"
              >
                <RfStatus :status="status.type" />
                {{ status.action }}
              </button>
            </template>
          </template>
        </RfMenu>
      </span>
    </td>
    <td class="pl-0">
      <div class="flex items-center gap-5 5xl:gap-7">
        <span
          ref="imagePreviewRef"
          class="rf-row--thumbnail rf-thumbnail"
          :class="{ 'rf-thumbnail--preview': imagePreview }"
        >
          <div class="h-full w-full" :class="{ 'overflow-hidden rounded': !imagePreview }">
            <RfPromotionThumbnail
              v-if="guide.paths.length"
              :item="guide.paths[0]"
              :key="guide.id"
            />
            <div v-else class="flex h-full w-full bg-icon">
              <span class="m-auto text-xs font-bold uppercase !text-white-1">No prompts yet</span>
            </div>
            <button
              v-if="guide.paths.length"
              class="rf-thumbnail--overlay text-body absolute inset-0 flex rounded !text-white-1 opacity-0 hover:opacity-100"
              @click="clickPreview"
            >
              <span class="rf-thumbnail--text m-auto hidden items-center gap-1">
                <RfEyeOpenIcon class="!h-6 !w-6 !fill-white-1" />
                Preview
              </span>
            </button>
          </div>
          <span
            v-if="!imagePreview"
            aria-hidden="true"
            class="text-body absolute -bottom-1 -right-1 z-0 grid h-6 w-6 place-content-center rounded-full bg-grey-1 p-1 !text-white-1"
          >
            {{ guide.paths.length }}
          </span>
        </span>
        <span class="flex flex-col gap-1">
          <div class="flex items-start gap-2">
            <RouterLink
              class="text-action-buttons line-clamp-1 text-ellipsis !font-bold !text-blue-1"
              :title="guide.name"
              :to="getGuideLink(guide.id, $route.params.aid)"
            >
              {{ guide.name }}
            </RouterLink>
            <button
              v-if="!disabled"
              class="flex items-center gap-3 opacity-0 transition-opacity duration-300 ease-in-out group-hover/row:opacity-100"
              @click="$emit('edit')"
            >
              <RfEditIcon class="!h-5 !w-5" />
            </button>
          </div>
          <span class="text-body">
            Show prompts: {{ GUIDE_TYPES[guide.sequence_type].label || GUIDE_TYPES.survey.label }}
          </span>
          <span class="text-body"
            >Creator:
            <component
              :is="guide.creator?.id ? 'button' : 'span'"
              class="!text-blue-1"
              @click="
                guide.creator?.id &&
                  $emit('filterUser', guide.creator.id, getUserName(guide.creator))
              "
            >
              {{ getUserName(guide.creator) }}
            </component>
          </span>
        </span>
      </div>
    </td>
    <td class="w-0">
      <div class="rf-row--segment-wrapper">
        <template v-if="guide.segments.length">
          <RouterLink
            v-for="segment in [...guide.segments].sort((a, b) =>
              `${a.name}`.localeCompare(`${b.name}`),
            )"
            :title="segment.name"
            class="!text-blue-1"
            :to="getSegmentLink(segment.id, $route.params.aid)"
          >
            {{ segment.name }}
          </RouterLink>
        </template>
        <span v-else>No segments</span>
      </div>
    </td>
    <td class="whitespace-nowrap px-4">
      <span class="text-body !text-black-2">
        {{ dayjs(guide.start_date).utc().format(`${dateOrder()}/YY`) }}
      </span>
    </td>
    <td class="whitespace-nowrap px-4">
      <span class="text-body relative !text-black-2">
        {{ dayjs(guide.end_date).utc().format(`${dateOrder()}/YY`) }}
        <RfPromptDateTag
          class="absolute -left-2 top-full mt-3 box-content"
          :date="new Date(guide.end_date)"
          :isExpired="guide.is_expired"
          :isLimited="getGuideStatus.type === StatusesSimplifiedConstants.limited.type"
        />
      </span>
    </td>
    <td v-for="colVal in [currUsers, currGoals]" class="rf-row--metric-col">
      <RfTooltip class="mr-0 w-fit" v-if="!colVal">
        <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
        <template #tooltip>
          <div class="w-64">{{ emptyDataText }}</div>
        </template>
      </RfTooltip>
      <span v-else class="text-body text-decoration-none !text-black-2">{{
        toLocaleNumberString(colVal)
      }}</span>
    </td>
    <td class="rf-row--metric-col">
      <RfTooltip class="mr-0 w-fit" v-if="!currConversions.value">
        <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
        <template #tooltip>
          <div class="w-64">{{ emptyDataText }}</div>
        </template>
      </RfTooltip>
      <span v-else>{{ currConversions.label }}</span>
    </td>
    <td class="whitespace-nowrap px-2">
      <div class="flex items-center justify-center">
        <RfMenu v-if="!disabled" hideIcon preset="promptMenu">
          <template #activator="{ opened }">
            <RfDotsIcon
              class="m-auto flex !h-6 !w-6 opacity-0 transition-opacity duration-300 ease-in-out group-hover/row:opacity-100"
              :class="{ '!opacity-100': opened }"
            />
          </template>
          <template #menu>
            <button
              class="flex w-full items-center gap-3 px-4 py-2 pt-4 first:!pt-4"
              @click="$emit('edit')"
            >
              <RfEditIcon class="!h-6 !w-6" />
              Edit
            </button>
            <button class="flex w-full items-center gap-3 px-4 py-2" @click="$emit('clone')">
              <RfCloneIcon class="!h-6 !w-6" />
              Clone
            </button>
            <button
              class="flex w-full items-center gap-3 px-4 py-2"
              @click="exportMultiplePromptToCsv(guide.paths, timePeriod)"
            >
              <RfDownloadIcon class="!h-6 !w-6" />
              Export All Prompts to CSV
            </button>
            <button
              class="flex w-full items-center gap-3 px-4 py-2 pb-4 last:!pb-4"
              @click="$emit('delete')"
            >
              <RfDeleteIcon class="!h-6 !w-6" />
              Delete
            </button>
          </template>
        </RfMenu>
      </div>
    </td>
    <div
      class="pointer-events-none absolute inset-0 bg-blue-1 opacity-0 transition-opacity duration-300 ease-in-out group-hover/row:opacity-5"
      :class="{ '!opacity-5': selected }"
    ></div>
  </tr>
</template>

<script>
import RfCheckbox from "@/components/inputs/RfCheckbox.vue";
import getPromptStatus from "@/utils/prompts/getPromptStatus";
import RfMenu from "@/components/menus/RfMenu.vue";
import StatusesConstants, {
  StatusesSimplifiedConstants,
  SimplifyStatusesMapConstants,
} from "@/utils/constants/PromptStatusesConstants";
import RfPromotionThumbnail from "@/components/RfPromotions/RfPromotionThumbnail.vue";
import RfDotsIcon from "@/components/icons/RfDotsIcon.vue";
import { getSegmentLink, getGuideLink } from "@/utils/getLink";
import { tally, percentage, statsAggregator } from "@/utils/metricsHelpers";
import RfEditIcon from "@/components/icons/RfEditIcon.vue";
import RfCloneIcon from "@/components/icons/RfCloneIcon.vue";
import RfHelpIcon from "@/components/icons/RfHelpIcon.vue";
import RfDeleteIcon from "@/components/icons/RfDeleteIcon.vue";
import RfPromptDateTag from "@/components/tags/RfPromptDateTag.vue";
import RfTooltip from "@/components/tooltip/RfTooltip.vue";
import RfEyeOpenIcon from "@/components/icons/RfEyeOpenIcon.vue";
import dayjs from "dayjs";
import { GUIDE_TYPES } from "@/utils/constants/GuidesConstants";
import RfStatus from "@/blocks/RfPrompts/RfStatus.vue";
import RfDownloadIcon from "@/components/icons/RfDownloadIcon.vue";
import { getUserName, toLocaleNumberString } from "@/utils/stringHelpers";
import { usePreviewToggle } from "@/utils/composables/usePreviewToggle ";
import { ref } from "vue";
import { dateOrder } from "@/utils/DateDisplayUtils";

const emptyDataText =
  "There is no data for this period of time. Try selecting a different time range or make sure the guide has been active.";

export default {
  name: "RfGuidesRow",
  setup: () => {
    const imagePreviewRef = ref(null);
    const imagePreview = ref(false);
    const clickPreview = usePreviewToggle(imagePreviewRef, imagePreview);

    return {
      StatusesSimplifiedConstants,
      StatusesConstants,
      SimplifyStatusesMapConstants,
      getSegmentLink,
      getGuideLink,
      emptyDataText,
      dayjs,
      GUIDE_TYPES,
      toLocaleNumberString,
      imagePreview,
      clickPreview,
      imagePreviewRef,
      dateOrder,
      getUserName,
    };
  },
  components: {
    RfCheckbox,
    RfMenu,
    RfPromotionThumbnail,
    RfDotsIcon,
    RfEditIcon,
    RfCloneIcon,
    RfDeleteIcon,
    RfPromptDateTag,
    RfHelpIcon,
    RfTooltip,
    RfEyeOpenIcon,
    RfStatus,
    RfDownloadIcon,
  },
  props: {
    guide: { type: Object, default: () => ({}) },
    selected: { type: Boolean, default: false },
    timezone: { type: String, default: null },
    timePeriod: { type: String, default: null },
    disabled: { type: [Boolean, String], default: false },
  },
  computed: {
    data() {
      return statsAggregator(this.guide.paths, this.timePeriod);
    },
    getGuideStatus() {
      return getPromptStatus(this.guide, this.timezone);
    },
    currUsers() {
      return tally(this.data, this.timePeriod, "uimpressions");
    },
    currClicks() {
      return tally(this.data, this.timePeriod, "goals");
    },
    currGoals() {
      return tally(this.data, this.timePeriod, "goals");
    },
    currConversions() {
      return this.currUsers ? percentage(this.currClicks, this.currUsers, "new") : { value: 0 };
    },
  },
};
</script>

<style lang="scss" scoped>
.rf-row {
  &--metric-col {
    @apply text-body whitespace-nowrap bg-goal px-4 text-right no-underline;
    @apply text-black-2 #{!important};
  }

  &--segment-wrapper {
    @apply text-body flex w-24 flex-col break-keep 4xl:w-40;
    @apply ml-0 #{!important};

    > * {
      @apply truncate break-keep;
    }
  }

  &--thumbnail {
    --duration-z-index: 350ms;
    z-index: 1;
    @apply relative mr-1 block h-24 w-32 flex-shrink-0;
    transform-origin: center top;
    transition:
      transform 300ms cubic-bezier(0.5, 1.2, 0.5, 1.2),
      z-index var(--duration-z-index) linear;
    z-index: 1;

    &.rf-thumbnail--preview {
      --duration-z-index: 0ms;
      transform: scale(2);
      z-index: 2;
    }

    .promo-preview-image {
      min-width: auto;
      height: 100%;

      img {
        @apply h-full w-full object-cover;
      }
    }

    &:not(.rf-thumbnail--preview) {
      .rf-thumbnail--overlay:hover {
        background: rgb(36, 36, 36, 0.6);
      }

      .rf-thumbnail--text {
        @apply inline-flex;
      }
    }

    .rf-thumbnail--overlay {
      transition: opacity 300ms ease-in-out;
    }
  }
}
</style>
