import { onMounted, ref } from "vue";
import { parseJsonSilently, stringifyJsonSilently } from "../JsonUtls";

export const SEGMENTS_SEARCH = "segments";
export const PROMPTS_SEARCH = "prompts";
export const GUIDES_SEARCH = "guides";
const JSON_KEY = "RfSearchInputs";
const ALL_SEARCH_TYPES = [SEGMENTS_SEARCH, PROMPTS_SEARCH, GUIDES_SEARCH];

const fetched = ref(false);
const searchInputs = ref(Object.fromEntries(ALL_SEARCH_TYPES.map(v => [v, ""])));

export const useSearchInputs = () => {
  if (!fetched.value) {
    onMounted(() => {
      fetched.value = true;
      const jsonInputs = parseJsonSilently(sessionStorage.getItem(JSON_KEY));

      if (jsonInputs) {
        searchInputs.value = Object.fromEntries(
          ALL_SEARCH_TYPES.map(v => {
            const value = jsonInputs?.[v];
            return [v, typeof value === "object" ? value : {}];
          })
        );
      }
    });
  }

  const writeToStorage = (type, appId, value) => {
    if (typeof searchInputs.value[type] !== "object") {
      searchInputs.value[type] = {};
    }
    searchInputs.value[type][appId] = value;
    sessionStorage.setItem(JSON_KEY, stringifyJsonSilently(searchInputs.value));
  };

  return { inputs: searchInputs, write: writeToStorage };
};
