<template>
  <div>
    <div class="metadata-div-header">
      <div @click="addField" class="add-metadata-btn">
        <v-icon color="primary" size="16" left>add</v-icon>Add metadata
      </div>
    </div>
    <div class="device-metadata" v-for="(item, index) in metadata">
      <v-text-field
        outlined
        dense
        hide-details
        label="Key"
        v-model.trim="item.key"
        name="device_key"
      ></v-text-field>
      <v-text-field
        outlined
        dense
        hide-details
        label="Value"
        v-model.trim="item.value"
        name="device_value"
      ></v-text-field>
      <v-icon size="18" @click="onRemove(index)">clear</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "RfRetentionActionsMetadata",
  props: ["app", "actions", "model"],
  data() {
    return {
      metadata: [],
    };
  },
  computed: {},
  watch: {
    metadata: {
      handler(to) {
        const results = to
          .filter(item => !!item.key)
          .reduce((hash, { key, value }) => {
            hash[key] = value;
            return hash;
          }, {});
        const stringified = JSON.stringify(results);
        this.actions.rf_metadata = stringified;
      },
      deep: true,
    },
  },
  methods: {
    addField() {
      this.metadata.push({
        key: null,
        value: null,
      });
    },
    onRemove(index) {
      this.metadata.splice(index, 1);
    },
    getMetadata() {
      let values = {};
      if (this.actions.rf_metadata && this.actions.rf_metadata.length) {
        values = JSON.parse(this.actions.rf_metadata);
      }

      const res = Object.entries(values).reduce(
        (arr, [key, value]) => [...arr, { key, value }],
        [],
      );
      return res;
    },
  },
  mounted() {
    this.metadata = this.getMetadata();
  },
};
</script>
<style lang="scss" scoped>
.add-metadata-btn {
  display: inline-block;
  padding: 0 10px 5px;
  color: #3096ed;
  font-weight: bold;
  cursor: pointer;
}
.metadata-div-header {
  border-bottom: 1px solid #ccc;
  text-align: right;
  margin-bottom: 10px;
}
.device-metadata {
  display: flex;
  flex-direction: row;
  padding: 10px 0;

  > div.v-input {
    flex: 1;
    padding-right: 16px;
  }
  > div.v-input:last-child {
    flex: 1;
    padding-right: 0px;
  }
}
</style>
