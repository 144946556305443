<template>
  <tr class="rf-row" :class="{ 'after:!opacity-5': selected }">
    <td class="whitespace-nowrap pr-0" style="min-width: calc(24px + 1rem)">
      <RfCheckbox
        class="rf-row--checkbox py-1"
        :class="{ 'rf-row--checkbox-col-selected': selected }"
        :value="selected"
        @input="$emit(selected ? 'deselect' : 'select', prompt.id)"
      />
    </td>
    <td class="w-full !pl-2 !pr-0 5xl:!pl-7">
      <div class="flex items-center justify-start gap-2 5xl:gap-7">
        <span class="flex flex-shrink-0 flex-grow-0 items-center justify-start">
          <RfMenu
            preset="promptStatus"
            class="w-11 flex-grow-0 !justify-start 5xl:w-27"
            :disabled="disableStatusChanges"
          >
            <template #activator>
              <span class="inline-flex items-center gap-2">
                <RfStatus data-cy="prompt-row--status" :status="getPromptStatus.type" />
                <span class="text-body hidden w-14 text-center 5xl:inline-block">
                  {{ StatusesConstants[getPromptStatus.type].title }}
                </span>
              </span>
            </template>
            <template #menu>
              <template v-for="status in StatusesSimplifiedConstants">
                <button
                  v-if="
                    status.type === SimplifyStatusesMapConstants.ready.type
                      ? !prompt.is_enabled && dayjs(prompt.start_date).isAfter(dayjs())
                      : ![
                          SimplifyStatusesMapConstants[getPromptStatus.type].type,
                          SimplifyStatusesMapConstants.limited.type,
                        ].includes(status.type)
                  "
                  :key="status.type"
                  class="flex w-full items-center gap-3 px-4 py-2 first:!pt-4 last:!pb-4"
                  @click="$emit('status', status.type)"
                >
                  <RfStatus :status="status.type" />
                  {{ status.action }}
                </button>
              </template>
            </template>
          </RfMenu>
        </span>
        <div class="flex items-center gap-2">
          <span
            ref="imagePreviewRef"
            class="rf-row--thumbnail rf-thumbnail"
            :class="{ 'rf-thumbnail--preview': imagePreview }"
          >
            <div class="h-full w-full" :class="{ 'overflow-hidden rounded': !imagePreview }">
              <RfPromotionThumbnail :item="prompt" :key="prompt.id" />
              <button class="rf-thumbnail--overlay rounded" @click="clickPreview">
                <span class="rf-thumbnail--text">
                  <RfEyeOpenIcon class="!h-6 !w-6 !fill-white-1" />
                  Preview
                </span>
              </button>
            </div>
          </span>
          <span class="flex flex-col gap-1">
            <div class="flex items-start gap-2">
              <RouterLink
                class="text-action-buttons line-clamp-1 text-ellipsis text-left !font-bold !text-blue-1"
                :title="prompt.name"
                :to="getPromptLink(prompt.id, $route.params.aid)"
              >
                {{ prompt.name }}
              </RouterLink>
              <button v-if="!disabled" class="rf-row--edit-inline" @click="$emit('edit')">
                <RfEditIcon class="!h-5 !w-5" />
              </button>
            </div>
            <span class="text-body">{{ getPromptDeviceType(prompt, customDevicesEnabled) }}</span>
            <span v-if="prompt.sequence?.id || prompt.pipeline?.id" class="text-body">
              Inherited from:
              <RouterLink
                v-if="prompt.sequence?.id"
                :to="getGuideLink(prompt.sequence.id, $route.params.aid)"
                class="!text-blue-1"
                v-text="prompt.sequence.name"
              />
              <RouterLink
                v-if="prompt.pipeline?.id"
                :to="getPipelineLink(prompt.pipeline.id, $route.params.aid)"
                class="!text-blue-1"
                v-text="prompt.pipeline.name"
              />
            </span>
            <span class="text-body"
              >Creator:
              <component
                :is="prompt.creator?.id ? 'button' : 'span'"
                class="!text-blue-1"
                @click="
                  prompt.creator?.id &&
                    $emit('filterUser', prompt.creator.id, getUserName(prompt.creator))
                "
              >
                {{ getUserName(prompt.creator) }}
              </component>
            </span>
          </span>
        </div>
      </div>
    </td>
    <td class="w-0">
      <div class="text-body flex w-28 flex-col items-start gap-2 [&>*]:max-w-28">
        <template v-if="prompt.tags.length">
          <RfColorTag
            v-for="tag in prompt.tags.slice(0, 2)"
            :id="tag.id"
            :title="tag.title"
            class="!p-0 [&>*]:truncate [&>*]:break-keep [&>*]:!py-1"
            pill
            disabled
            :backgroundColor="tag.color"
          />

          <span ref="tooltipAnchorEl">
            <button
              v-if="prompt.tags.length > 2"
              class="inline-flex"
              @focus="() => tooltipEl.show()"
              @mouseenter="() => tooltipEl.show()"
              @blur="() => tooltipEl.hide()"
              @mouseleave="() => tooltipEl.hide()"
            >
              <RfSeeMoreIcon class="rf-row--see-more" />
            </button>
          </span>

          <RfTooltip
            ref="tooltipEl"
            :anchor="tooltipAnchorEl"
            class="rf-row--configuration-tooltip"
          >
            <template #tooltip>
              <div class="flex flex-wrap gap-1">
                <RfColorTag
                  v-for="tag in prompt.tags"
                  :id="tag.id"
                  :title="tag.title"
                  class="!p-0 [&>*]:truncate [&>*]:break-keep [&>*]:!py-1"
                  pill
                  disabled
                  :backgroundColor="tag.color"
                />
              </div>
            </template>
          </RfTooltip>
        </template>
        <span v-else>No tags</span>
      </div>
    </td>

    <template v-if="!performanceCols">
      <td class="w-0">
        <div
          class="text-body !ml-0 flex w-24 flex-col break-keep 5xl:w-40 [&>*]:truncate [&>*]:break-keep"
        >
          <template v-if="prompt.segments.length">
            <RouterLink
              v-for="segment in [...prompt.segments].sort((a, b) =>
                `${a.name}`.localeCompare(`${b.name}`),
              )"
              :title="segment.name"
              class="!text-blue-1"
              :to="getSegmentLink(segment.id, $route.params.aid)"
            >
              {{ segment.name }}
            </RouterLink>
          </template>
          <span v-else>No segments</span>
        </div>
      </td>
      <td class="whitespace-nowrap px-4">
        <span class="text-body !text-black-2">
          {{ dayjs(prompt.start_date).utc().format(`${dateOrder()}/YY`) }}
        </span>
      </td>
      <td class="whitespace-nowrap px-4">
        <span class="text-body relative !text-black-2">
          {{ dayjs(prompt.end_date).utc().format(`${dateOrder()}/YY`) }}
          <RfPromptDateTag
            class="absolute -right-2 top-full mt-3 box-content"
            :date="new Date(prompt.end_date)"
            :isExpired="prompt.is_expired"
            :isLimited="getPromptStatus.type === StatusesSimplifiedConstants.limited.type"
          />
        </span>
      </td>
    </template>

    <td class="rf-row--metric-col">
      <RfTooltip class="mr-0 w-fit" v-if="!currUsers">
        <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
        <template #tooltip>
          <div class="w-64">{{ emptyDataText }}</div>
        </template>
      </RfTooltip>
      <span
        v-else
        class="text-body text-decoration-none"
        :class="greyedStats ? '!text-grey-4' : '!text-black-2'"
        >{{ toLocaleNumberString(currUsers) }}</span
      >
    </td>

    <template v-if="performanceCols">
      <td v-for="colVal in [currImpressions, currClicks]" class="rf-row--metric-col">
        <RfTooltip class="mr-0 w-fit" v-if="!colVal">
          <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
          <template #tooltip>
            <div class="w-64">{{ emptyDataText }}</div>
          </template>
        </RfTooltip>
        <span
          v-else
          class="text-body text-decoration-none"
          :class="greyedStats ? '!text-grey-4' : '!text-black-2'"
          >{{ toLocaleNumberString(colVal) }}</span
        >
      </td>
      <td class="rf-row--metric-col">
        <RfTooltip class="mr-0 w-fit" v-if="!currCtr.value">
          <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
          <template #tooltip>
            <div class="w-64">{{ emptyDataText }}</div>
          </template>
        </RfTooltip>
        <span
          v-else
          class="text-body text-decoration-none"
          :class="greyedStats ? '!text-grey-4' : '!text-black-2'"
          >{{ currCtr.label }}</span
        >
      </td>
    </template>

    <td class="rf-row--metric-col">
      <RfTooltip class="mr-0 w-fit" v-if="!hasCustomGoalAndSinglePath || !currGoals">
        <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
        <template #tooltip>
          <div class="w-64">{{ emptyDataText }}</div>
        </template>
      </RfTooltip>
      <span
        v-else
        class="text-body text-decoration-none"
        :class="greyedStats ? '!text-grey-4' : '!text-black-2'"
        >{{ toLocaleNumberString(currGoals) }}</span
      >
    </td>

    <td class="rf-row--metric-col">
      <RfTooltip class="mr-0 w-fit" v-if="!currConversions.value">
        <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
        <template #tooltip>
          <div class="w-64">{{ emptyDataText }}</div>
        </template>
      </RfTooltip>
      <span
        v-else
        class="text-body text-decoration-none"
        :class="greyedStats ? '!text-grey-4' : '!text-black-2'"
        >{{ currConversions.label }}</span
      >
    </td>
    <td class="whitespace-nowrap px-2">
      <div class="flex items-center justify-center">
        <RfMenu hideIcon preset="promptMenu">
          <template #activator="{ opened }">
            <RfDotsIcon class="rf-row--menu" :class="{ 'rf-row--menu-opened': opened }" />
          </template>
          <template #menu>
            <button
              v-if="!disabled"
              class="flex w-full items-center gap-3 px-4 py-2 first:!pt-4"
              @click="$emit('edit')"
            >
              <RfEditIcon class="!h-6 !w-6" />
              Edit
            </button>
            <button
              v-if="!prompt.sequence?.id && !disabled"
              class="flex w-full items-center gap-3 px-4 py-2"
              @click="$emit('clone')"
            >
              <RfCloneIcon class="!h-6 !w-6" />
              Clone
            </button>
            <RouterLink
              :to="`${getPromptLink(prompt.id, $route.params.aid)}/analysis`"
              class="flex w-full items-center gap-3 px-4 py-2 first:!pt-4"
              style="color: inherit"
            >
              <RfDiagramsIcon class="!h-6 !w-6" />
              Performance
            </RouterLink>
            <button
              class="flex w-full items-center gap-3 px-4 py-2"
              @click="exportSinglePromptToCsv(prompt, timePeriod)"
            >
              <RfDownloadIcon class="!h-6 !w-6" />
              Export to CSV
            </button>
            <RouterLink
              :to="`${getPromptLink(prompt.id, $route.params.aid)}/experiments`"
              class="flex w-full items-center gap-3 px-4 py-2 last:!pb-4"
              style="color: inherit"
            >
              <RfExperimentIcon class="!h-6 !w-6" />
              Experiments
            </RouterLink>
            <button
              v-if="!disabled"
              class="flex w-full items-center gap-3 px-4 py-2 last:!pb-4"
              @click="$emit('delete')"
            >
              <RfDeleteIcon class="!h-6 !w-6" />
              Delete
            </button>
          </template>
        </RfMenu>
      </div>
    </td>
  </tr>
</template>

<script>
import RfCheckbox from "@/components/inputs/RfCheckbox.vue";
import RfStatus from "./RfStatus.vue";
import getPromptStatus from "@/utils/prompts/getPromptStatus";
import RfMenu from "@/components/menus/RfMenu.vue";
import StatusesConstants, {
  StatusesSimplifiedConstants,
  SimplifyStatusesMapConstants,
} from "@/utils/constants/PromptStatusesConstants";
import RfPromotionThumbnail from "@/components/RfPromotions/RfPromotionThumbnail.vue";
import RfDotsIcon from "@/components/icons/RfDotsIcon.vue";
import { getSegmentLink, getPromptLink, getGuideLink, getPipelineLink } from "@/utils/getLink";
import { getPromptDeviceType } from "@/utils/getDeviceName";
import { tally, percentage } from "@/utils/metricsHelpers";
import RfEditIcon from "@/components/icons/RfEditIcon.vue";
import RfDiagramsIcon from "@/components/icons/RfDiagramsIcon.vue";
import RfCloneIcon from "@/components/icons/RfCloneIcon.vue";
import RfHelpIcon from "@/components/icons/RfHelpIcon.vue";
import RfExperimentIcon from "@/components/icons/RfExperimentIcon.vue";
import RfDeleteIcon from "@/components/icons/RfDeleteIcon.vue";
import RfPromptDateTag from "@/components/tags/RfPromptDateTag.vue";
import RfTooltip from "@/components/tooltip/RfTooltip.vue";
import RfEyeOpenIcon from "@/components/icons/RfEyeOpenIcon.vue";
import dayjs from "dayjs";
import { exportSinglePromptToCsv } from "@/utils/CsvUtils";
import RfDownloadIcon from "@/components/icons/RfDownloadIcon.vue";
import { isInvisible } from "@/utils/prompts/promptHelpers";
import { getUserName, toLocaleNumberString } from "@/utils/stringHelpers";
import { usePreviewToggle } from "@/utils/composables/usePreviewToggle ";
import { ref } from "vue";
import RfColorTag from "@/components/tags/RfColorTag.vue";
import RfSeeMoreIcon from "@/components/icons/RfSeeMoreIcon.vue";
import { dateOrder } from "@/utils/DateDisplayUtils";
import { StandalonePrompts } from "@/utils/constants/PromoTypesConstants";

const emptyDataText =
  "There is no data for this period of time. Try selecting a different time range or make sure the prompt has been active.";

export default {
  name: "RfPromptsRow",
  setup: () => {
    const imagePreviewRef = ref(null);
    const imagePreview = ref(false);
    const tooltipEl = ref();
    const tooltipAnchorEl = ref();
    const clickPreview = usePreviewToggle(imagePreviewRef, imagePreview);

    return {
      StatusesSimplifiedConstants,
      SimplifyStatusesMapConstants,
      StatusesConstants,
      getSegmentLink,
      getPromptLink,
      getGuideLink,
      getPipelineLink,
      getPromptDeviceType,
      emptyDataText,
      dayjs,
      exportSinglePromptToCsv,
      toLocaleNumberString,
      imagePreview,
      imagePreviewRef,
      clickPreview,
      tooltipEl,
      tooltipAnchorEl,
      dateOrder,
      getUserName,
    };
  },
  components: {
    RfCheckbox,
    RfStatus,
    RfMenu,
    RfPromotionThumbnail,
    RfDotsIcon,
    RfEditIcon,
    RfDiagramsIcon,
    RfCloneIcon,
    RfExperimentIcon,
    RfDeleteIcon,
    RfPromptDateTag,
    RfHelpIcon,
    RfTooltip,
    RfEyeOpenIcon,
    RfDownloadIcon,
    RfColorTag,
    RfSeeMoreIcon,
    StandalonePrompts,
  },
  props: {
    prompt: { type: Object, default: () => ({}) },
    selected: { type: Boolean, default: false },
    timezone: { type: String, default: null },
    timePeriod: { type: String, default: null },
    disabled: { type: [Boolean, String], default: false },
    performanceCols: { type: Boolean, default: false },
    customDevicesEnabled: { type: Boolean, default: false },
    isTagsSelected: { type: Boolean, default: false },
  },
  computed: {
    getPromptStatus() {
      return getPromptStatus(this.prompt, this.timezone);
    },
    promptCustomFiltersKeys() {
      return Object.keys(this.prompt?.custom_filter || {});
    },
    hasCustomGoalAndSinglePath() {
      return this.promptCustomFiltersKeys.length > 0;
    },
    greyedStats() {
      return this.isTagsSelected
        ? [
            StandalonePrompts.email.value,
            StandalonePrompts.invisible.value,
            StandalonePrompts.scheduled_push.value,
          ].includes(this.prompt.path_type)
        : false;
    },
    currUsers() {
      return tally(
        this.prompt.data,
        this.timePeriod,
        isInvisible(this.prompt) ? "users" : "uimpressions",
      );
    },
    currImpressions() {
      return tally(this.prompt.data, this.timePeriod, "impressions");
    },
    currCtr() {
      if (!this.currImpressions) return { value: 0 };

      return percentage(this.currClicks, this.currImpressions, "new");
    },
    currClicks() {
      return tally(this.prompt.data, this.timePeriod, "goals");
    },
    currGoals() {
      return tally(this.prompt.data, this.timePeriod, "custom_goals_seen");
    },
    currConversions() {
      if (!this.currUsers) return { value: 0 };
      return percentage(
        this.hasCustomGoalAndSinglePath ? this.currGoals : this.currClicks,
        this.currUsers,
        "new",
      );
    },
    disableStatusChanges() {
      return (
        this.disabled ||
        !!this.prompt.sequence?.id ||
        !!this.prompt.pipeline?.id ||
        this.getPromptStatus.type === SimplifyStatusesMapConstants.limited.type
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.rf-row--configuration-tooltip .rf-tooltip {
  max-width: 225px;
}

.rf-row {
  @apply relative;

  &--see-more {
    @apply z-10 rounded-full bg-input-background;
    @apply h-6 w-6 #{!important};
    transition: background-color 0.3s ease-out;
  }

  &--metric-col {
    @apply text-body whitespace-nowrap bg-goal px-4 text-right no-underline;
    @apply text-black-2 #{!important};
  }

  &--edit-inline {
    @apply flex items-center gap-3 opacity-0;
  }

  &::after {
    @apply pointer-events-none absolute inset-0 bg-blue-1 opacity-0 transition-opacity duration-300 ease-in-out;
    content: "";
  }

  &--checkbox:not(.rf-row--checkbox-col-selected),
  &--menu:not(.rf-row--menu-opened) {
    opacity: 0;
  }

  &--checkbox,
  &--edit-inline,
  &--menu {
    transition: opacity 0.3s ease-out;
  }

  &--menu {
    @apply m-auto flex;
    @apply h-6 w-6 #{!important};
  }

  &:hover {
    &::after {
      @apply opacity-5;
    }

    .rf-row--see-more {
      @apply bg-white-1;
    }

    .rf-row--checkbox {
      opacity: 1;
    }

    .rf-row--menu {
      opacity: 1;
    }

    .rf-row--edit-inline {
      opacity: 1;
    }
  }

  &--thumbnail {
    --duration-z-index: 350ms;
    @apply relative block h-24 w-32 flex-shrink-0;
    transform-origin: center top;
    transition:
      transform 300ms cubic-bezier(0.5, 1.2, 0.5, 1.2),
      z-index var(--duration-z-index) linear;
    z-index: 1;

    &.rf-thumbnail--preview {
      --duration-z-index: 0ms;
      transform: scale(2);
      z-index: 2;
    }

    .promo-preview-image {
      min-width: auto;
      height: 100%;

      img {
        @apply h-full w-full object-cover;
      }
    }

    &:not(.rf-thumbnail--preview) {
      .rf-thumbnail--overlay:hover {
        background: rgb(36, 36, 36, 0.6);
      }

      .rf-thumbnail--text {
        @apply inline-flex;
      }
    }

    .rf-thumbnail--overlay {
      @apply text-body absolute inset-0 flex opacity-0 hover:opacity-100;
      @apply text-white-1 #{!important};
      transition: opacity 300ms ease-in-out;
    }

    .rf-thumbnail--text {
      @apply m-auto hidden items-center gap-1;
    }
  }
}
</style>
