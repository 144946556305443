<template>
  <RfSimpleCard>
    <div class="m-px p-1">
      <RfSettingsTagsCreateModal
        ref="createTagModal"
        @create="tag => tagsStore.createTag(currApp?.id, tag)"
      />

      <RfSettingsTagsEditModal
        v-if="editItem && currApp?.flags?.enable_tags_freq_cap"
        ref="editTagModal"
        :tag="editItem"
        @edit="tag => tagsStore.editTag(currApp?.id, tag)"
        @close="editItem = null"
      />

      <RfBaseModal
        v-if="deleteItem"
        ref="deleteModal"
        message="This cannot be undone."
        width="580"
        error-text="Delete"
        @error="submitDelete(deleteItem.id)"
        @close="deleteItem = null"
      >
        <template #title>
          Are you sure you want to delete
          <span class="whitespace-pre font-bold"> {{ deleteItem.title }} </span> ?
        </template>
      </RfBaseModal>

      <RfTable
        :tableData="tagsStore.table"
        class="rf-table"
        :rowSkeleton="RfSettingsTagsRowSkeleton"
        :loading="tagsStore.loading && !tagsStore.tags?.length"
        hideTotal
        @loadMore="() => tagsStore.loadTable(true)"
      >
        <template #thead>
          <RfSettingsTagsHeader :sort="sort" @update:sort="v => (sort = v)" />
        </template>
        <template #tbody>
          <template v-if="tagsStore.renderLength">
            <RfSettingsTagsRow
              v-for="i in tagsStore.renderLength"
              :key="`${tagsStore.tags[i - 1].id}_${i}`"
              :title="tagsStore.tags[i - 1].title"
              :description="tagsStore.tags[i - 1].description"
              :creator="tagsStore.tags[i - 1].creator_name"
              :color="tagsStore.tags[i - 1].color"
              :currApp="currApp"
              @delete="
                () => ((deleteItem = tagsStore.tags[i - 1]), nextTick(() => deleteModal.show()))
              "
              @edit="
                () => ((editItem = tagsStore.tags[i - 1]), nextTick(() => editTagModal.showEditTagModal()))
              "
            />
          </template>
        </template>
      </RfTable>
    </div>
  </RfSimpleCard>
</template>

<script setup>
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useTagsStore } from "@/pinia/tagsStore";
import store from "@/store";
import RfSettingsTagsCreateModal from "./RfSettingsTagsCreateModal.vue";
import RfSettingsTagsEditModal from "./RfSettingsTagsEditModal.vue";
import RfTable from "@/blocks/RfTable/RfTable.vue";
import RfSettingsTagsRow from "./RfSettingsTagsRow.vue";
import { useTableSort } from "@/utils/composables/useTableSort";
import RfSettingsTagsHeader from "./RfSettingsTagsHeader.vue";
import RfSettingsTagsRowSkeleton from "./RfSettingsTagsRowSkeleton.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";

const props = defineProps({ app: { type: Object, default: () => ({}) } });

const deleteItem = ref(null);
const editItem = ref(null);
const deleteModal = ref(null);
const createTagModal = ref();
const editTagModal = ref();
const tagsStore = useTagsStore();
const sort = useTableSort(["default"]);

const currApp = computed(() => store.state?.apps?.currApp);

const click = () => createTagModal.value?.show();
onMounted(() => tagsStore.getTags(currApp.value?.id));

watch([sort], () => tagsStore.getTags(currApp.value?.id), { deep: true });

const submitDelete = tagId => {
  tagsStore.deleteTag(currApp.value.id, tagId);
  deleteModal.value.close();
};

defineExpose({ click });
</script>

<style lang="scss" scoped></style>
