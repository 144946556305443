<template>
  <tr class="text-body h-0 border-t border-solid border-strokes">
    <td class="whitespace-nowrap !px-4 !py-2 text-black-2">
      {{ dayjs(props.timestamp, { format: "YYYYDDMMHHmm" }).format("MMM D, YYYY HH:mm:ss") }}
    </td>
    <td v-if="errorTab" class="!px-4 !py-2 text-black-2">
      <span class="inline-flex max-w-full" :title="props.connector || '-'">
        <span class="truncate whitespace-nowrap">
          {{ props.connector || "-" }}
        </span>
      </span>
    </td>
    <td class="break-words !px-4 !py-2 text-black-2">
      {{ props.activity || props.error || "-" }}
    </td>
    <td v-if="fullView" class="!px-4 !py-2 text-black-2">
      <div class="flex flex-col">
        <RouterLink
          class="text-heading-2 line-clamp-1 !text-blue-1"
          :to="getPromptLink(props.prompt_id, props.appId)"
        >
          {{ props.prompt_name }}
        </RouterLink>
        <span v-if="props.experiment_name"> Experiment: {{ props.experiment_name }}</span>
        <span v-if="props.variation_name"> Variation: {{ props.variation_name }}</span>
      </div>
    </td>
    <td class="break-words !px-4 !py-2 text-black-2" v-if="survey">
      {{ props.survey_option }}
    </td>
    <td class="!px-4 !py-2 text-black-2">
      <button
        class="inline-flex max-w-full items-center gap-2"
        :title="props.user_id"
        @click="copyToClipboard(props.user_id, 'User id copied to the clipboard')"
      >
        <span class="truncate whitespace-nowrap">
          {{ props.user_id }}
        </span>
        <RfCopyIcon class="!h-6 !w-6 flex-shrink-0" />
      </button>
    </td>
  </tr>
</template>

<script setup>
import { getPromptLink } from "@/utils/getLink";
import dayjs from "dayjs";
import copyToClipboard from "@/utils/CopyToClipboard";
import RfCopyIcon from "@/components/icons/RfCopyIcon.vue";

const props = defineProps({
  fullView: { type: Boolean, default: false },
  timestamp: { type: String, default: dayjs().format("YYYYDDMMHHmm") },
  user_id: { type: String, default: "-" },
  prompt_name: { type: String, default: "-" },
  prompt_id: { type: String, default: "" },
  appId: { type: String, default: "" },
  variation_name: { type: String, default: "" },
  experiment_name: { type: String, default: "" },
  activity: { type: String, default: "" },
  connector: { type: String, default: "" },
  error: { type: String, default: "" },
  errorTab: { type: Boolean, default: false },
  survey: { type: Boolean, default: false },
  survey_option: { type: String, default: "-" },
});
</script>
