<template>
  <div v-if="viewTypeTab === 'desktopViewTab'">
    <v-expansion-panels v-model="getPromoPanel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>Message</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsTitleMessage :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Background</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div>
            <v-text-field
              outlined
              dense
              hide-details
              label="Background Color"
              v-model.trim="actions.rf_settings_bg_image_color"
              :error-messages="errors.first('bg-image-color')"
              name="bg-image-color"
              placeholder="e.g.: #336699"
              style="padding: 5px 0 2px"
            ></v-text-field>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>User Interaction</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <v-radio-group
            row
            hide-details
            class="inline-interactions-opt"
            v-model="actions.rf_settings_tile_interaction"
          >
            <v-radio value="container" label="Block" />
            <v-radio value="none" label="None" />
          </v-radio-group>
          <div
            class="promo-design-group interactions-opt-item"
            v-if="actions.rf_settings_tile_interaction === 'none'"
          >
            <div style="padding-bottom: 15px">
              <v-checkbox
                v-model="actions.rf_settings_close_button_enabled"
                label="Allow close"
                value="true"
                class="promotion-closeBtn-checkbox"
              />
            </div>
            <div v-if="actions.rf_settings_close_button_enabled">
              <div style="padding-bottom: 20px">
                <RfPromoInteractionInterval
                  interactionType="dismiss"
                  :action="actions.rf_settings_dismiss_interval"
                  v-on:setIntervalAction="setIntervalAction"
                />
              </div>
              <RfRetentionActionsBehavior :model="model" :actions="actions" displayOff />
            </div>
            <span class="gray-text">Only dismiss and timeout actions are possible</span>
          </div>
          <div
            class="promo-design-group interactions-opt-item"
            v-if="actions.rf_settings_tile_interaction === 'container'"
          >
            <RfTileContainerOptions :model="model" :actions="actions" />
          </div>
          <RfPromoInteractionInterval
            interactionType="clickButton1"
            :action="actions.rf_settings_accept_interval"
            v-on:setIntervalAction="setIntervalAction"
            v-if="!isSticky"
          />
          <div>
            <v-checkbox
              v-model="thankYouEnabled"
              label="Show confirmation message"
              class="background-img-checkbox"
            />
            <v-text-field
              v-if="thankYouEnabled"
              :class="thankYouEnabled ? '' : 'disabled-button'"
              dense
              outlined
              required
              label="Confirmation message"
              placeholder="e.g.: Thank you!"
              v-model.trim="actions.rf_retention_acceptance_text"
              background-color="#ffffff"
              hide-details
              style="margin: 0 0 5px 28px"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type === 'web'">
        <v-expansion-panel-header>Countdown Timer</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsCountdown :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="inApp">
        <v-expansion-panel-header>In-App Purchase</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsInapp :actions="actions" :app="app" :model="model" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type !== 'web'">
        <v-expansion-panel-header>Deep Link</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsDeeplink :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type !== 'web'">
        <v-expansion-panel-header>Device Metadata</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsMetadata :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>CSS</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="promo-design-group">
            <RfPromoCustomCSS :actions="actions" />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  <div v-else>
    <v-expansion-panels v-model="getPromoPanel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>CONFIGURATION</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsMobileWeb :app="app" :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>CSS</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfPromoCustomCSS :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import RfRetentionActionsTitleMessage from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsTitleMessage.vue";
import RfRetentionActionsBehavior from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsBehavior.vue";
import UploadImageMixin from "@/utils/UploadImageMixin";
import RfRetentionActionsMobileWeb from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsMobileWeb.vue";
import RfPromoCustomCSS from "@/components/RfPromotions/RfPromoCustomCSS.vue";
import RfPromoInteractionInterval from "@/components/RfPromotions/RfPromoInteractionInterval.vue";
import RfTileContainerOptions from "@/components/RfPathCreate/RfRetentionActions/RfTileContainerOptions.vue";
import RfRetentionActionsCountdown from "./RfRetentionActionsCountdown.vue";
import RfRetentionActionsInapp from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsInapp.vue";
import RfRetentionActionsDeeplink from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsDeeplink.vue";
import RfRetentionActionsMetadata from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsMetadata.vue";
import { hasInapp } from "@/utils/prompts/promptHelpers";

export default {
  name: "RfRetentionActionsTextPromo",
  components: {
    RfRetentionActionsTitleMessage,
    RfRetentionActionsBehavior,
    RfRetentionActionsMobileWeb,
    RfPromoCustomCSS,
    RfPromoInteractionInterval,
    RfTileContainerOptions,
    RfRetentionActionsCountdown,
    RfRetentionActionsInapp,
    RfRetentionActionsDeeplink,
    RfRetentionActionsMetadata,
  },
  mixins: [UploadImageMixin],
  props: ["actions", "model", "app", "viewTypeTab"],
  data() {
    return {
      getPromoPanel: [0],
      thankYouEnabled: this.model.id ? !!this.actions.rf_retention_acceptance_text : true,
    };
  },
  methods: {
    setIntervalAction(value, type) {
      this.actions[`rf_settings_${type}_interval`] = value;
    },
  },
  computed: {
    isSticky() {
      return (
        this.actions.rf_settings_permanent_display === "true" &&
        this.actions.rf_settings_tile_interaction === "container"
      );
    },
    inApp() {
      return hasInapp(this.model);
    },
  },
  watch: {
    thankYouEnabled(to) {
      if (!to) {
        this.actions.rf_retention_acceptance_text = null;
      }
    },
  },
};
</script>
