<template>
  <div v-if="viewTypeTab === 'desktopViewTab'">
    <v-expansion-panels v-model="getPromoPanel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>Message</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsTitleMessage :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="!isPromoTile">
        <v-expansion-panel-header>Size</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="promo-design-group">
            <v-text-field
              hide-details
              outlined
              dense
              label="Message Width"
              v-model.trim="actions.rf_settings_text_container_max_width"
              name="fill_color"
              required
              v-validate="'required'"
            ></v-text-field>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Background</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionImageUpload :actions="actions" :model="model" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>User Interaction </v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <v-radio-group
            row
            hide-details
            class="inline-interactions-opt"
            v-model="actions.rf_settings_tile_interaction"
            v-if="model.device_type === 'web' || app.flags.custom_devices"
          >
            <v-radio value="button" label="Buttons" />
            <v-radio value="container" label="Block" />
            <v-radio value="none" label="None" />
          </v-radio-group>
          <div
            class="promo-design-group interactions-opt-item"
            v-if="
              actions.rf_settings_tile_interaction === 'none' &&
              (model.device_type === 'web' || app.flags.custom_devices)
            "
          >
            <div>
              <v-checkbox
                v-model="actions.rf_settings_close_button_enabled"
                label="Allow close"
                value="true"
                class="promotion-closeBtn-checkbox"
              />
            </div>
            <div v-if="actions.rf_settings_close_button_enabled">
              <div style="padding-bottom: 20px">
                <RfPromoInteractionInterval
                  interactionType="dismiss"
                  :action="actions.rf_settings_dismiss_interval"
                  v-on:setIntervalAction="setIntervalAction"
                />
              </div>
              <RfRetentionActionsBehavior :model="model" :actions="actions" displayOff />
            </div>
            <span class="gray-text">Only dismiss and timeout actions are possible</span>
          </div>
          <div
            class="promo-design-group interactions-opt-item"
            v-if="actions.rf_settings_tile_interaction === 'container'"
          >
            <RfTileContainerOptions :model="model" :actions="actions" />
          </div>
          <div
            class="promo-design-group interactions-opt-item"
            v-if="actions.rf_settings_tile_interaction === 'button'"
          >
            <RfRetentionButtons
              :actions="actions"
              :model="model"
              :isCustomDevicesEnabled="app.flags.custom_devices"
              :showCloseOptions="true"
              v-on:forceRender="$emit('forceRender')"
            />
            <RfRetentionButtonsAlignment
              :actions="actions"
              :model="model"
              v-on:forceRender="$emit('forceRender')"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type === 'web'">
        <v-expansion-panel-header>Form</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionFieldSet :model="model" :actions="actions" :requireAcceptButton="true" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type === 'web'">
        <v-expansion-panel-header>Countdown Timer</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsCountdown :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="inApp">
        <v-expansion-panel-header>In-App Purchase</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsInapp :actions="actions" :app="app" :model="model" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type !== 'web'">
        <v-expansion-panel-header>Deep Link</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsDeeplink :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type !== 'web'">
        <v-expansion-panel-header>Device Metadata</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsMetadata :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>CSS</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="promo-design-group">
            <RfPromoCustomCSS :actions="actions" />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  <div v-else>
    <v-expansion-panels v-model="getPromoPanel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>CONFIGURATION</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsMobileWeb :app="app" :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>CSS</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfPromoCustomCSS :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import RfRetentionActionsTitleMessage from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsTitleMessage.vue";
import RfRetentionActionsDeeplink from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsDeeplink.vue";
import RfRetentionActionsMetadata from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsMetadata.vue";
import RfRetentionActionsBehavior from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsBehavior.vue";
import RfRetentionButtons from "@/components/RfPathCreate/RfRetentionActions/RfRetentionButtons.vue";
import RfRetentionButtonsAlignment from "@/components/RfPathCreate/RfRetentionActions/RfRetentionButtonsAlignment.vue";
import RfRetentionActionsInapp from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsInapp.vue";
import RfRetentionActionsMobileWeb from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsMobileWeb.vue";
import RfPromoCustomCSS from "@/components/RfPromotions/RfPromoCustomCSS.vue";
import RfTileContainerOptions from "@/components/RfPathCreate/RfRetentionActions/RfTileContainerOptions.vue";
import RfPromoInteractionInterval from "@/components/RfPromotions/RfPromoInteractionInterval.vue";
import RfRetentionImageUpload from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionImageUpload.vue";
import RfRetentionFieldSet from "@/components/RfPathCreate/RfRetentionActions/RfRetentionFieldSet.vue";
import RfRetentionActionsCountdown from "./RfRetentionActionsCountdown.vue";
import { hasInapp } from "@/utils/prompts/promptHelpers";

export default {
  name: "RfRetentionActionsInlinePromo",
  components: {
    RfRetentionActionsTitleMessage,
    RfRetentionActionsDeeplink,
    RfRetentionActionsBehavior,
    RfRetentionButtons,
    RfRetentionButtonsAlignment,
    RfRetentionActionsInapp,
    RfRetentionActionsMobileWeb,
    RfPromoCustomCSS,
    RfTileContainerOptions,
    RfRetentionActionsMetadata,
    RfPromoInteractionInterval,
    RfRetentionImageUpload,
    RfRetentionFieldSet,
    RfRetentionActionsCountdown,
  },
  props: ["actions", "model", "app", "viewTypeTab"],
  data() {
    return {
      getPromoPanel: [0],
    };
  },
  computed: {
    inApp() {
      return hasInapp(this.model);
    },
    isPromoTile() {
      return this.model.path_type === "tile" || this.model.path_type === "vertical";
    },
  },
  methods: {
    setIntervalAction(value, type) {
      this.actions[`rf_settings_${type}_interval`] = value;
    },
  },
  watch: {},
};
</script>
