<template>
  <div class="promo-design-inputs">
    <v-expansion-panels v-model="getPromoPanel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>Message</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsTitleMessage :model="model" :actions="actions" :app="app" noStyle />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Image</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="flex flex-col gap-2">
            <div class="upload-bgimg-wrapper">
              <v-btn
                depressed
                color="accent"
                class="upload-image-btn"
                @click="iosImageInput?.click?.()"
              >
                <v-icon size="14" left>fas fa-upload</v-icon>Upload iOS image
              </v-btn>
              <input
                type="file"
                class="hidden"
                ref="iosImageInput"
                accept="image/*"
                @change="uploadBgImageFromInput('pushIos')"
              />
              <RfImageFileInfo
                :image="actions.rf_pinpoint_ios_image"
                :filename="actions.rf_pinpoint_ios_image_filename"
              />
              <div class="upload-img-helper">1:1 aspect ratio</div>
            </div>
            <div class="upload-bgimg-wrapper">
              <v-btn
                depressed
                color="accent"
                class="upload-image-btn"
                @click="androidImageInput?.click?.()"
              >
                <v-icon size="14" left>fas fa-upload</v-icon>Upload android image
              </v-btn>
              <input
                type="file"
                class="hidden"
                ref="androidImageInput"
                accept="image/*"
                @change="uploadBgImageFromInput('pushAndroid')"
              />
              <RfImageFileInfo
                :image="actions.rf_settings_bg_image"
                :filename="actions.rf_settings_bg_image_filename"
              />
              <div class="upload-img-helper">2:1 aspect ratio</div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Action</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="flex flex-col gap-2">
            <v-select
              outlined
              dense
              hide-details
              :items="[
                { value: 'OPEN_APP', text: 'Open app' },
                { value: 'DEEP_LINK', text: 'Open app to deep link' },
                { value: 'URL', text: 'Open url' },
              ]"
              label="Action"
              v-model.trim="actions.rf_pinpoint_campaign_action"
            />
            <v-text-field
              v-if="['URL', 'DEEP_LINK'].includes(actions.rf_pinpoint_campaign_action)"
              outlined
              dense
              hide-details
              label="Link"
              v-model.trim="actions.rf_settings_deeplink"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import RfRetentionActionsTitleMessage from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsTitleMessage.vue";
import UploadImageMixin from "@/utils/UploadImageMixin";
import RfImageFileInfo from "@/components/RfPathCreate/RfRetentionActions/components/RfImageFileInfo.vue";
export default {
  components: { RfRetentionActionsTitleMessage, RfImageFileInfo },
  mixins: [UploadImageMixin],
  props: ["actions", "model", "app"],
  setup: props => {
    const getPromoPanel = ref([0]);
    const iosImageInput = ref();
    const androidImageInput = ref();
    watchEffect(() => {
      if (props.actions.rf_pinpoint_campaign_action === "OPEN_APP") {
        props.actions.rf_settings_deeplink = null;
      }
    });

    return { getPromoPanel, iosImageInput, androidImageInput };
  },
};
</script>
