export const DefaultDeviceOptions = {
  web: { value: "web", text: "Web" },
  ios: { value: "ios", text: "iPhone/iPad" },
  tv_os: { value: "tv_os", text: "Apple TV" },
  android_os: { value: "android_os", text: "Android" },
  roku_os: { value: "roku_os", text: "Roku" },
};

export const DeviceTypeWording = {
  ...DefaultDeviceOptions,
  custom_defined: { value: "custom_defined", text: "Custom" },
};

export const getSegmentTypeDevices = customDevicesEnabled => ({
  ...SegmentTypeDevices,
  ...(customDevicesEnabled && { [customSegmentTypeDevice.value]: customSegmentTypeDevice }),
});

export const customSegmentTypeDevice = {
  name: "Custom",
  child: "customDevices",
  value: "custom_defined",
};

export const SegmentTypeDevices = {
  os_family: { name: "OS", child: "deviceOs", value: "os_family" },
  browser_family: { name: "Browsers", child: "browsers", value: "browser_family" },
  device_family: { name: "Manufacturer", child: "deviceType", value: "device_family" },
  device_target: { name: "SDK", child: "devices", value: "device_target" },
};

export const DeviceTypes = {
  iPhone: { name: "Apple iPhone", value: "iPhone" },
  iPad: { name: "Apple iPad", value: "iPad" },
  Mac: { name: "Apple Mac", value: "Mac" },
  Amazon: { name: "Amazon", value: "AFT" },
  Nexus: { name: "Nexus", value: "Nexus" },
  Pixel: { name: "Pixel", value: "Pixel" },
  Samsung: { name: "Samsung", value: "Samsung" },
  Moto: { name: "Motorola", value: "Moto" },
  GenericSmartphone: { name: "Generic Smartphone", value: "Generic Smartphone" },
  LG: { name: "LG", value: "LG" },
  Huawei: { name: "Huawei", value: "Huawei" },
  Lumia: { name: "Lumia", value: "Lumia" },
  Vivo: { name: "Vivo", value: "vivo" },
  Oppo: { name: "Oppo", value: "Oppo" },
  OnePlus: { name: "OnePlus", value: "OnePlus" },
  Poco: { name: "Poco", value: "POCO" },
  Kindle: { name: "Kindle", value: "Kindle" },
  Other: { name: "Other", value: "Other" },
};

export const DeviceOSes = {
  Android: { name: "Android", value: "Android" },
  iOS: { name: "iOS", value: "iOS" },
  Windows: { name: "Windows", value: "Windows" },
  Mac: { name: "MacOS", value: "Mac OS X" },
  Linux: { name: "Linux", value: "Linux" },
  Roku: { name: "Roku", value: "Roku" },
  Tizen: { name: "Tizen", value: "Tizen" },
  WebOS: { name: "WebOS", value: "WebOS" },
  Chrome: { name: "Chrome", value: "Chrome OS" },
  Other: { name: "Other", value: "Other" },
};

export const Browsers = {
  "Chrome": { name: "Chrome", value: "Chrome" },
  "Chrome Mobile": { name: "Chrome Mobile", value: "Chrome Mobile" },
  "Safari": { name: "Safari", value: "Safari" },
  "Mobile Safari": { name: "Mobile Safari", value: "Mobile Safari" },
  "Chrome iOS": { name: "Chrome iOS", value: "Chrome Mobile iOS" },
  "Edge": { name: "Edge", value: "Edge" },
  "Edge Mobile": { name: "Edge Mobile", value: "Edge Mobile" },
  "Firefox": { name: "Firefox", value: "Firefox" },
  "Firefox Mobile": { name: "Firefox Mobile", value: "Firefox Mobile" },
  "Firefox iOS": { name: "Firefox iOS", value: "Firefox iOS" },
  "Opera": { name: "Opera", value: "Opera" },
  "Google": { name: "Google", value: "Google" },
  "Opera Mobile": { name: "Opera Mobile", value: "Opera Mobile" },
  "Samsung Internet": { name: "Samsung Browser", value: "Samsung Internet" },
  "Amazon Silk": { name: "Amazon Silk", value: "Amazon Silk" },
};

export const Devices = [
  { name: "Android Phone", value: { device_type: "android_os", device_category: "phone" } },
  { name: "Android Tablet", value: { device_type: "android_os", device_category: "tablet" } },
  { name: "Android TV", value: { device_type: "android_os", device_category: "tv" } },
  { name: "Desktop & Mobile Web", value: { device_type: "web" } },
  {
    name: "Fire Tablet",
    value: { device_type: "android_os", device_manufacturer: "Amazon", device_model: "^KF" },
  },
  {
    name: "Fire TV",
    value: { device_type: "android_os", device_manufacturer: "Amazon", device_model: "^AFT" },
  },
  {
    name: "Google TV",
    value: { device_type: "android_os", device_category: "tv", device_model: "Google TV" },
  },
  {
    name: "Sony TV",
    value: { device_type: "android_os", device_category: "tv", device_model: "^BRAVIA" },
  },
  {
    name: "Chromecast",
    value: { device_type: "android_os", device_category: "tv", device_model: "^Chromecast" },
  },
  { name: "Apple iPhone", value: { device_type: "ios", device_model: "^iPhone" } },
  { name: "Apple iPad", value: { device_type: "ios", device_model: "^iPad" } },
  { name: "Apple TV", value: { device_type: "tv_os" } },
  {
    name: "Roku (1080p)",
    value: { device_type: "roku_os", device_model: "^(3800|3900|3910|3930|3931|8000|D000|H000)" },
  },
  {
    name: "Roku (All)",
    value: {
      device_type: "roku_os",
      device_model: "^(38|39|44|46|48|60|70|80|91|A0|C0|D0|G0|H0|E0)",
    },
  },
];