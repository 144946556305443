/**
 * Description
 * @param {Number} value
 * @returns {String}
 */
export const toLocaleNumberString = (value, fraction = 0) => {
  if (typeof value !== "number" && !(value instanceof Number)) value = +value;
  return Number.isNaN(value)
    ? "0"
    : value.toLocaleString(undefined, {
        minimumFractionDigits: fraction,
        maximumFractionDigits: fraction,
      });
};

/**
 * Description
 * @param {Object} user
 * @param {string} toast.name = 'N/A'
 * @param {string} [toast.first_name]
 * @param {string} [toast.last_name]
 * @param {boolean} [toast.is_redfast = false]
 * @returns {String}
 */
export const getUserName = (user = { name: "N/A", is_redfast: false }) => {
  let userName = "N/A";
  if (!user) return userName;
  if (user.first_name) userName = `${user.first_name}${user.last_name ? ` ${user.last_name}` : ""}`;
  else if (user.name) userName = user.name;
  if (user.is_redfast) return `(Redfast) ${userName}`;
  return userName;
};
