<template>
  <RfBaseModal
    ref="modalRef"
    title="Edit tag"
    width="459"
    secondaryText="Submit"
    closeText="Cancel"
    @close="closeEditTagModal"
    @secondary="emit('edit', tag) && closeEditTagModal()"
  >
    <template #body>
      <div class="flex flex-col">
        <RfTextInput
          dense
          outlined
          persistent-hint
          class="pb-1"
          placeholder="Tag 1"
          counter="15"
          maxlength="15"
          title="Name"
          :rules="[value => !!value || 'Name field is required.']"
          v-model.trim="tag.title"
        ></RfTextInput>
        <RfTextInput
          dense
          outlined
          persistent-hint
          data-cy="element-info--name"
          placeholder="A tag for..."
          title="Description (optional)"
          class="pb-1"
          v-model.trim="tag.description"
        ></RfTextInput>
        <div class="flex flex-col gap-1">
          <div>Color</div>
          <div class="flex gap-1">
            <RfColorTag
              v-for="color in tagsColors"
              :backgroundColor="color"
              class="h-10 w-10"
              :selected="tag.color === color"
              @click="tag.color = color"
            />
          </div>
        </div>
        <div class="tag-freq-cap-div" ref="freqCapFields">
          <div class="font-bold text-lg mt-6">
            Frequency Cap
          </div>
          <v-radio-group
            v-model="isCapped"
            class="pt-0 mt-2"
            @change="updateFrequencyCap"
          >
            <v-radio :value="false" label="None" />
            <v-radio :value="true" label="Custom" />
          </v-radio-group>
          <div v-if="isCapped" class="pt-2">
            <div>
              Limit the number of prompts seen by a user.
              {{ freqCapHint }}
            </div>
            <div class="frequency-cap-val items-center gap-2">
              <RfSelect
                v-model="capType"
                outlined
                dense
                :items="[VISIT, DAYS]"
                name="cap_type"
                hint="Select the type of limit"
                persistent-hint
                background-color="#fff"
                @change="updateFrequencyCap"
              ></RfSelect>
            </div>
            <div class="frequency-cap-val">
              <RfTextInput
                id="limit-count"
                v-model.trim="tag.filter.frequency_cap.cap"
                outlined
                dense
                title="Limit count"
                hint="Max number of prompts that can be shown to a user."
                persistent-hint
              />
            </div>
            <div class="frequency-cap-val items-center gap-2" v-if="capType === DAYS.value">
              <RfSelect
                v-model="tag.filter.frequency_cap.cap_seconds"
                outlined
                dense
                :items="capValues"
                title="Reset after"
                hint="Time period before cap is reset."
                persistent-hint
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </RfBaseModal>
</template>

<script setup>
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import RfColorTag from "@/components/tags/RfColorTag.vue";
import { ref, watch, computed } from "vue";
import dayjs from "dayjs";
import { tagsColors } from "@/utils/constants/ColorsConstants";
import { VISIT, DAYS } from "@/utils/constants/FrequencyCapConstants";

const emit = defineEmits(["edit"]);

const props = defineProps({
  tag: { type: Object, default: () => ({}) },
});

const modalRef = ref();
const freqCapFields = ref();
const tag = ref({ title: "", description: "", color: "", filter: {} });

const isCapped = ref(false);
const capType = ref();

const capValues = computed(() => [
  ...[15, 30, 45].map((el) => ({
    text: `${el} minutes`,
    value: el * 60,
  })),
  { text: "1 hour", value: 3600 },
  ...new Array(30).fill().map((_, i) => ({
    text: `${i + 1} day${i > 0 ? "s" : ""}`,
    value: (i + 1) * 86400,
  })),
]);

const freqCapHint = computed(() => {
  if (tag.value.filter?.frequency_cap?.cap_seconds === VISIT.value) {
    return "Specify the maximum number of prompts with this tag that should be shown to a user per visit before the limit is reset.";
  }
  return "Specify the maximum number of prompts with this tag that should be shown to a user and the number of days before the limit is reset.";
});

const updateFrequencyCap = () => {
  if (!isCapped.value) {
    tag.value.filter.frequency_cap = null;
  } else {
    tag.value.filter.frequency_cap = {
      is_capped: true,
      cap: tag.value.filter.frequency_cap?.cap,
      cap_seconds: tag.value.filter.frequency_cap?.cap_seconds,
    };
  }
};

const showEditTagModal = () => modalRef.value?.show();
const closeEditTagModal = () => emit("close");

watch(
  () => props.tag,
  (newTag) => {
    if (newTag) {
      tag.value = JSON.parse(JSON.stringify(newTag));
      tag.value.filter = tag.value.filter || {};
      tag.value.filter.frequency_cap = tag.value.filter.frequency_cap || {};
      isCapped.value = !!tag.value.filter.frequency_cap.is_capped;
      capType.value = tag.value.filter.frequency_cap.cap_seconds === VISIT.value ? VISIT.value : DAYS.value;
    }
  },
  { immediate: true }
);

defineExpose({ showEditTagModal, closeEditTagModal });
</script>
