<template>
  <div class="rf-live">
    <slot name="title" />
    <div class="rf-live--header flex flex-wrap items-center justify-end gap-x-4 gap-y-3">
      <RfTableFilter
        v-if="fullView"
        buttonSize="base"
        buttonType="white"
        class="rf-status-menu z-10 flex-none rounded border border-solid border-strokes"
        :selected="filters"
        :filters="{
          devices: { el: getAppDevices, key: 'value', value: 'text', name: 'Device Platform' },
          prompts: { el: getAllPrompts(), key: 'key', value: 'value', name: 'Prompt type' },
        }"
        @selected="items => (filters = { ...filters, ...items })"
      >
        <template #activator>
          <span class="text-body !text-black-2"> Device / type </span>
        </template>
      </RfTableFilter>
      <RfTableFilter
        buttonSize="base"
        buttonType="white"
        class="rf-status-menu z-10 flex-none rounded border border-solid border-strokes"
        :selected="filters"
        :filters="{
          activities: { el: allActivities, key: 'key', value: 'value' },
        }"
        @selected="items => (filters = { ...filters, ...items })"
      >
        <template #activator>
          <span class="text-body !text-black-2"> Activity type </span>
        </template>
      </RfTableFilter>
      <div class="flex-1">
        <RfInput
          v-model="searchInput"
          placeholder="Search by User ID"
          class="!flex max-w-96 rounded border border-solid border-strokes"
        />
      </div>
      <div class="align-center flex flex-none justify-end gap-5">
        <RfSwitch title="Auto update" v-model="liveAutoUpdate" />
        <RfButtonNew
          :disabled="skeleton"
          class="min-w-40 px-0"
          @click="fetchMetrics(), startAutoUpdate()"
        >
          <span v-if="skeleton" class="flex items-center justify-center gap-2">
            <RfSpinnerIcon class="!h-6 !w-6 animate-spin !fill-white-1" />
            Updating
          </span>
          <span v-else class="flex items-center justify-center gap-2">
            <RfResetIcon class="!h-5 !w-5 !stroke-white-1" />
            Update
            {{ interval.currentTick ? `in ${interval.MAX_TICKS - interval.currentTick}` : "" }}
          </span>
        </RfButtonNew>
      </div>
      <RfDatePicker
        v-if="fullView"
        ref="datePicker"
        :min="
          currApp?.flags?.one_year_metrics
            ? dayjs().subtract(1, 'year')
            : dayjs().subtract(90, 'days')
        "
        :max="dayjs()"
        @input="v => (datepickerDate = v)"
      />
    </div>
    <div class="flex items-center gap-4" :class="{ '-mt-4': fullView }">
      <TransitionGroup
        class="flex flex-grow-0 items-center gap-4 overflow-y-hidden overflow-x-scroll"
        mode="out-in"
        tag="div"
      >
        <template v-for="(filter, filterKey) in filters">
          <RfBaseTag
            v-for="(el, key) in filter"
            :key="`${filterKey}-${key}`"
            class="mt-4 !bg-input-background"
            @clear="removeFilter(filterKey, key)"
          >
            {{ el }}
          </RfBaseTag>
        </template>
        <button
          v-if="filtersLength"
          key="clear-button"
          class="text-body mt-4 flex-shrink-0"
          @click="filters = {}"
        >
          Clear all filters ({{ filtersLength }})
        </button>
      </TransitionGroup>
    </div>
    <div class="flex flex-col gap-3 rounded bg-white-1 py-5" :class="{ 'px-5': fullView }">
      <Transition mode="out-in">
        <RfBaseSkeleton v-if="skeletonOnce" height="29" width="120" />
        <h2 v-else class="text-heading-1 !text-black-1">Activity</h2>
      </Transition>

      <div class="flex flex-col">
        <div class="text-action-buttons mx-auto inline-flex h-6 gap-1 !text-black-2">
          <Transition mode="out-in" tag="span">
            <span v-if="!skeleton && chart.hasData && dataValues?.total">
              <span class="font-bold">{{ toLocaleNumberString(dataValues?.total) }}</span>
              events
            </span>
          </Transition>
        </div>
        <RfChart ref="chart" :skeleton="skeleton" :data="metricsFormatted" noLegend />
      </div>
    </div>
    <div class="flex flex-col gap-3 rounded">
      <RfTable
        :tableData="table"
        class="rf-table"
        :rowSkeleton="RfTableRowSkeleton"
        :loading="skeleton && !chart.hasData"
        @loadMore="() => initTable(true)"
      >
        <template #pretable>
          <div
            class="flex flex-col gap-3 rounded-t bg-white-1 pb-3 pt-5"
            :class="{ 'px-5': fullView }"
          >
            <div class="flex h-10 items-center justify-between gap-2">
              <Transition mode="out-in">
                <RfBaseSkeleton v-if="skeletonOnce" height="29" width="120" />
                <h2 v-else class="text-heading-1 !text-black-1">Live</h2>
              </Transition>
              <Transition mode="out-in">
                <RfBaseSkeleton v-if="skeletonOnce" height="40" width="250" />
                <span v-else-if="renderLength" class="inline-flex items-center gap-3">
                  <RfTooltip class="mr-0 w-fit">
                    <template #activator>
                      <RfHelpIcon
                        class="!h-7 !w-7 rounded-full border border-solid border-input-background"
                      />
                    </template>
                    <template #tooltip>
                      <div class="w-64">
                        Only the first 500 results matching your search will be exported
                      </div>
                    </template>
                  </RfTooltip>
                  <RfButtonNew :disabled="loadingCsv" @click="exportLiveDataToCsv">
                    <RfDownloadIcon class="!h-6 !w-6 !stroke-white-1" />
                    Export to CSV
                  </RfButtonNew>
                </span>
              </Transition>
            </div>
            <RfButtonTabs :active="tab">
              <RfButtonNew
                v-for="({ name, key }, i) in tabs"
                type="none"
                :key="key"
                class="px-4 py-2 !text-black-2"
                :class="{ '!font-bold': i === tab }"
                @click="tab = i"
              >
                {{ name }}
              </RfButtonNew>
            </RfButtonTabs>
          </div>
        </template>
        <template #thead>
          <RfTableHeader
            :fullView="fullView"
            :errorTab="tabs[tab].key === 'live-errors'"
            :sort="sort"
            :survey="surveyEnabled"
            @update:sort="v => (sort = v)"
            @update:filters="refetch()"
          />
        </template>
        <template #tbody>
          <template v-if="renderLength">
            <RfTableRow
              v-for="i in renderLength"
              :errorTab="tabs[tab].key === 'live-errors'"
              v-bind="data[tabs[tab].key]?.[date.preset?.value]?.hits[i - 1]"
              :key="data[tabs[tab].key]?.[date.preset?.value]?.hits[i - 1]?.document_id || i"
              :fullView="fullView"
              :activity="
                allActivities?.[data[tabs[tab].key]?.[date.preset?.value]?.hits?.[i - 1]?.activity]
                  ?.value
              "
              :appId="currApp.id"
              :survey="surveyEnabled"
            />
          </template>
          <tr v-if="table.showing >= 500" key="max">
            <td colspan="100%">
              <div class="text-body w-full text-center">
                These are the first 500 results matching your search, refine your search to see
                others
              </div>
            </td>
          </tr>
        </template>
      </RfTable>
    </div>
  </div>
</template>

<script>
import RfInput from "@/components/inputs/RfInput.vue";
import { computed, nextTick, onUnmounted, reactive, ref, watch } from "vue";
import { getAllDevices, getAllPrompts } from "@/utils/getDeviceName";
import RfTableFilter from "@/blocks/RfTable/RfTableFilter.vue";
import RfTableRow from "@/blocks/RfLive/RfTableRow.vue";
import RfTableHeader from "@/blocks/RfLive/RfTableHeader.vue";
import RfTableRowSkeleton from "@/blocks/RfLive/RfTableRowSkeleton.vue";
import { useTableFilter } from "@/utils/composables/useTableFilter";
import RfBaseTag from "@/components/tags/RfBaseTag.vue";
import { getAllActivities } from "@/utils/constants/PromoInteractionConstants";
import dayjs from "dayjs";
import RfDatePicker from "@/components/inputs/RfDatePicker.vue";
import { METRICS_PERIODS } from "@/utils/constants/MetricsConstants";
import RfButtonTabs from "@/components/buttons/RfButtonTabs.vue";
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import ApiApps from "@/apis/ApiApps";
import ApiPaths from "@/apis/ApiPaths";
import { useRoute } from "vue-router/composables";
import { debounce } from "@/utils/debounce";
import { useTableData } from "@/pinia/piniaUtils";
import RfTable from "@/blocks/RfTable/RfTable.vue";
import store from "@/store";
import { useTableSort } from "@/utils/composables/useTableSort";
import RfResetIcon from "@/components/icons/RfResetIcon.vue";
import RfSpinnerIcon from "@/components/icons/RfSpinnerIcon.vue";
import MetricsUtils from "@/utils/MetricsUtils";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import { chartColors } from "@/utils/constants/ColorsConstants";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import { getAllActivitiesForPrompt, isSurvey } from "@/utils/prompts/promptHelpers";
import RfChart from "@/blocks/RfCharts/RfChart.vue";
import RfBaseSkeleton from "@/components/skeletons/RfBaseSkeleton.vue";
import RfDownloadIcon from "@/components/icons/RfDownloadIcon.vue";
import { exportLiveDataToCsv } from "@/utils/CsvUtils";
import RfTooltip from "@/components/tooltip/RfTooltip.vue";
import RfHelpIcon from "@/components/icons/RfHelpIcon.vue";
import RfSwitch from "@/components/inputs/RfSwitch.vue";

export default {
  name: "RfLiveCard",
  components: {
    RfInput,
    RfTableFilter,
    RfTableRow,
    RfTableHeader,
    RfBaseTag,
    RfDatePicker,
    RfButtonTabs,
    RfButtonNew,
    RfTable,
    RfResetIcon,
    RfSpinnerIcon,
    RfChart,
    RfBaseSkeleton,
    RfDownloadIcon,
    RfTooltip,
    RfHelpIcon,
    RfSwitch,
  },
  mixins: [UserSettingsMixin],
  props: {
    dateFilter: Object,
    promptFilter: Object,
  },
  setup(props) {
    const colors = ref([...chartColors]);
    const tabs = [
      {
        name: "Activities",
        key: "live-activities",
        color: colors.value.splice(~~(Math.random() * colors.value.length), 1)[0],
      },
      {
        name: "Errors",
        key: "live-errors",
        color: colors.value.splice(~~(Math.random() * colors.value.length), 1)[0],
      },
    ];
    const route = useRoute();
    const data = ref({});
    const liveAutoUpdate = ref(null);
    const interval = reactive({ value: null, currentTick: 0, MAX_TICKS: 30 });
    const searchInput = ref("");
    const skeleton = ref(true);
    const skeletonOnce = ref(true);
    const chart = ref({ hasData: false });
    const tab = ref(0);
    const loadingCsv = ref(false);
    const datepickerDate = ref({});
    const debugData = ref(false);
    const { filters, removeFilter, filtersLength, filtersConverted } = useTableFilter();
    const { table, resetPage } = useTableData();
    const sort = useTableSort(["default"]);

    const currApp = computed(() => store.state?.apps?.currApp);
    const getAppDevices = computed(() =>
      getAllDevices(currApp.value.custom_devices, currApp.value.flags.custom_devices),
    );

    const date = computed(() =>
      datepickerDate.value?.preset?.value ? datepickerDate.value : (props.dateFilter ?? {}),
    );
    const fullView = computed(() => !props.promptFilter);

    const dataValues = computed(
      () => data.value?.[tabs[tab.value].key]?.[date.value?.preset?.value],
    );
    const renderLength = computed(() => {
      const dataLength = dataValues.value?.hits?.length || 0;
      return Math.min(dataLength, (table.page || 1) * table.perPage);
    });

    const requestMetrics = debounce(async () => {
      try {
        resetPage();
        table.perPage = 50;
        table.totalCount = 50;
        if (debugData.value) date.value.preset = METRICS_PERIODS.custom;
        const device_types = filtersConverted.value.devices?.array;
        const path_types = filtersConverted.value.prompts?.array;
        const activities = filtersConverted.value.activities?.array;

        const filters = {
          ...(device_types && { device_types }),
          ...(path_types && { path_types }),
          ...(activities && { activities }),
        };

        const params = {
          metric_key: tabs[tab.value].key,
          metric_periods: [
            {
              period: date.value.preset.value || METRICS_PERIODS.last_week.value,
              ...(date.value.preset.value === METRICS_PERIODS.custom.value && {
                date_range: [
                  date.value.startDate.format("YYYY-MM-DD"),
                  date.value.endDate.format("YYYY-MM-DD"),
                ],
              }),
            },
          ],
          ...(Object.keys(filters).length && { filters }),
          ...(searchInput.value && { search: { user_id: searchInput.value } }),
          ...(debugData.value && { dummy_response: true }),
        };

        const v = await (props.promptFilter
          ? ApiPaths.getLiveData(route.params.aid, props.promptFilter.id, params)
          : ApiApps.getLiveData(route.params.aid, params));

        data.value = {
          ...data.value,
          [tabs[tab.value].key]: {
            ...data.value[tabs[tab.value].key],
            [date.value.preset.value]: v[date.value.preset.value].agg_data,
          },
        };
        initTable();
      } catch (error) {
      } finally {
        skeleton.value = false;
        skeletonOnce.value = false;
      }
    }, 330);

    const fetchMetrics = () => {
      skeleton.value = true;
      return requestMetrics();
    };

    const initTable = (next = false) => {
      if (next) return (table.page = table.page + 1);
      table.page = 1;
      table.totalCount = data.value[tabs[tab.value].key][date.value.preset.value].hits.length;
      table.totalPages = table.totalCount / table.perPage;
    };

    const startAutoUpdate = () => {
      if (!date.value.preset?.value) return;
      const shouldUpdate =
        [
          METRICS_PERIODS.this_month.value,
          METRICS_PERIODS.this_week.value,
          METRICS_PERIODS.today.value,
          METRICS_PERIODS.last_seven_days.value,
          METRICS_PERIODS.since_start_date.value,
        ].includes(date.value.preset.value) ||
        (date.value.preset.value === METRICS_PERIODS.custom.value &&
          (date.value.startDate.isToday() || date.value.endDate.isToday()));
      destroyInterval();
      if (shouldUpdate && liveAutoUpdate.value) {
        interval.value = setInterval(autoUpdate, 1000);
        interval.currentTick++;
      }
    };

    const autoUpdate = () => {
      if (interval.currentTick < interval.MAX_TICKS) return interval.currentTick++;
      interval.currentTick = 0;
      fetchMetrics();
    };

    const destroyInterval = () => {
      clearInterval(interval.value);
      interval.value = null;
      interval.currentTick = 0;
    };

    onUnmounted(destroyInterval);

    watch([searchInput, tab, date, filters, sort], () => fetchMetrics(), { deep: true });

    const metricsFormatted = computed(() => {
      const dataLength = dataValues.value?.aggregations?.length || 0;
      if (dataLength === 0) return [];
      const chartData = [];
      chartData.push({
        label: "Users",
        data: Object.fromEntries(dataValues.value.aggregations.map(el => [el.date, el.count])),
        backgroundColor: tabs[tab.value].color,
      });
      return chartData;
    });

    const allActivities = computed(() =>
      props.promptFilter ? getAllActivitiesForPrompt(props.promptFilter) : getAllActivities(),
    );

    const surveyEnabled = computed(() => isSurvey(props.promptFilter));

    return {
      tabs,
      tab,
      data,
      interval,
      searchInput,
      skeleton,
      skeletonOnce,
      date,
      datepickerDate,
      filters,
      removeFilter,
      filtersLength,
      fullView,
      table,
      sort,
      currApp,
      getAppDevices,
      dataValues,
      renderLength,
      fetchMetrics,
      initTable,
      startAutoUpdate,
      metricsFormatted,
      RfTableRowSkeleton,
      getAllDevices,
      getAllPrompts,
      dayjs,
      datePicker: ref(),
      allActivities,
      toLocaleNumberString,
      chart,
      loadingCsv,
      destroyInterval,
      liveAutoUpdate,
      surveyEnabled,
    };
  },
  async mounted() {
    await nextTick();

    if (this.fullView) {
      const preset = MetricsUtils.All_Periods.find(
        ({ value }) =>
          value !== METRICS_PERIODS.since_start_date.value &&
          value === this.getUserSetting("liveDateRange"),
      )?.value;

      this.$refs.datePicker?.setTimeFromPreset(preset || METRICS_PERIODS.last_seven_days.value);
      this.liveAutoUpdate = this.getUserSetting("liveAutoUpdate") ?? true;
    } else {
      this.liveAutoUpdate = false;
    }
  },
  watch: {
    date: {
      handler(v) {
        this.startAutoUpdate();
        if (v?.preset?.value && v.preset.value !== METRICS_PERIODS.custom.value && this.fullView)
          this.setUserSetting("liveDateRange", v.preset.value);
      },
      deep: true,
    },
    liveAutoUpdate(v) {
      this.startAutoUpdate();
      if (this.getUserSetting("liveAutoUpdate") !== v) this.setUserSetting("liveAutoUpdate", v);
    },
  },
  methods: {
    exportLiveDataToCsv() {
      this.loadingCsv = true;
      exportLiveDataToCsv(
        this.dataValues?.hits,
        this.tabs[this.tab].key,
        this.date.preset?.value,
        this.surveyEnabled,
      );
      this.loadingCsv = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.rf-status-menu,
::v-deep.rf-datepicker {
  .rf-menu--button {
    @apply px-6;
  }
}

::v-deep.rf-table .rf-table--wrapper {
  @apply rounded-b bg-white-1 pb-5;

  table {
    @apply table-fixed;
  }
}

.rf-live--header :deep(.rf-input--input-wrapper.rf-input--base) {
  min-width: 10rem;
  max-width: 30rem;
}
</style>
