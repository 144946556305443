import ApiUsers from "@/apis/ApiUsers";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { catchable } from "./piniaUtils";
import store from "@/store";

export const ADMIN = "admin";
export const SUPER_ADMIN = "super_admin";
export const READONLY = "read_only";
export const MEMBER = "member";

export const useUsersStore = defineStore("users", () => {
  const users = ref([]);
  const lastGetUsersCompany = ref(null);
  const lastGetUsersTimestamp = ref(null);

  const currApp = computed(() => store.state?.apps?.currApp);

  const currUsersEx = computed(() =>
    currApp.value
      ? users.value.filter(
          user =>
            [ADMIN, SUPER_ADMIN].includes(user.role) ||
            user.apps.find(app => app.id === currApp.value.id),
        )
      : users.value,
  );
  const currUsers = computed(() =>
    currApp.value ? currUsersEx.value.filter(user => user.role !== SUPER_ADMIN) : users.value,
  );

  const mutateUpdateUser = user => {
    const index = users.value.findIndex(item => item.id === user.id);
    if (index === -1) return;
    const copyUsers = [...users.value];
    copyUsers[index] = user;
    users.value = copyUsers;
  };

  const mutateDeleteUser = userId => {
    const index = users.value.findIndex(item => item.id === userId);
    if (index === -1) return;
    const copyUsers = [...users.value];
    copyUsers.splice(index, 1);
    users.value = copyUsers;
  };

  const updateUser = ({ user, companyId, updatePermissions }) =>
    catchable({
      t: async () =>
        mutateUpdateUser(await ApiUsers.updateUser({ user, companyId, updatePermissions })),
    });

  const inviteUser = ({ user, companyId }) =>
    catchable({
      t: async () =>
        (users.value = [...users.value, await ApiUsers.inviteUser({ user, companyId })]),
    });

  const getUsers = (companyId, forceUpdate = false) =>
    catchable({
      t: async () => {
        if (!forceUpdate && users.value.length) {
          if (lastGetUsersCompany.value === companyId) return;
          if (
            lastGetUsersTimestamp.value &&
            Date.now() - lastGetUsersTimestamp.value < 1000 * 60 * 5
          )
            return;
        }
        users.value = await ApiUsers.getUsers(companyId);
        lastGetUsersCompany.value = companyId || null;
        lastGetUsersTimestamp.value = +Date.now();
      },
    });

  const deleteUser = ({ userId, isAdminingCompany, appId }) =>
    catchable({
      t: async () =>
        mutateDeleteUser(await ApiUsers.deleteUser({ userId, isAdminingCompany, appId })),
    });

  return { deleteUser, getUsers, inviteUser, updateUser, users, currUsers, currUsersEx };
});
