<template>
  <div class="segment-builder--wrapper" :class="{ 'show-mode': segmentEditMode }" v-if="notLocked">
    <RfModalLoading :showDialog="isUpdating" />
    <div class="segment-builder-wrapper-content">
      <div>
        <div
          v-for="(filter, row) in filters"
          :key="row + upkey"
          class="segment-builder-row-wrapper"
        >
          <v-select
            v-if="row > 0 && canOrLogic(filter)"
            dense
            outlined
            hide-details
            :items="[
              { value: false, text: 'AND' },
              { value: true, text: 'OR' },
            ]"
            :value="filterOrLogics[row]"
            @input="changeLogic($event, row)"
            class="segment-builder-logic"
            :class="{ 'segment-builder-logic--or': filterOrLogics[row] }"
            height="32px"
            :disabled="isReadOnlyRoleMixin"
          />
          <v-tooltip v-else-if="row > 0" right open-delay="500">
            <template #activator="{ on, attrs }">
              <div
                class="segment-builder-logic segment-builder-logic--and-only"
                v-on="on"
                v-bind="attrs"
              >
                AND
              </div>
            </template>
            <span>
              <strong>[Beta Feature]</strong><br />
              "OR" logic is only supported between "Custom" configurations.<br />
              Support for other types coming soon.
            </span>
          </v-tooltip>

          <div class="segment-builder-row">
            <div class="segment-builder--item _row">
              <v-tooltip :disabled="!canOrLogic(filter)" bottom open-delay="500">
                <template #activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-select
                      dense
                      outlined
                      hide-details
                      :items="availableTypesFor(row)"
                      item-text="name"
                      item-value="value"
                      :value="type(filter)"
                      @input="changeType($event, row)"
                      id="type"
                      class="segment-builder--type _type"
                      height="32px"
                      background-color="#ffffff"
                      placeholder="Select configuration"
                      :menu-props="{
                        'maxHeight': 410,
                        'offsetY': true,
                        'content-class': 'segment-builder--type-menu',
                      }"
                      :disabled="isReadOnlyRoleMixin || canOrLogic(filter)"
                    >
                      <template #item="{ item }">
                        <v-tooltip :disabled="!item.disabled" right open-delay="500">
                          <template #activator="slot">
                            <div class="v-list-item__content" v-on="slot.on" v-bind="slot.attrs">
                              <div class="v-list-item__title">{{ item.name }}</div>
                            </div>
                          </template>
                          <span>
                            <strong>[Beta Feature]</strong><br />
                            "Custom" configurations must be grouped together at the end
                          </span>
                        </v-tooltip>
                      </template>
                    </v-select>
                  </div>
                </template>
                <span>
                  <strong>[Beta Feature]</strong><br />
                  "Custom" configurations must be grouped together at the end
                </span>
              </v-tooltip>
            </div>
            <div class="segment-builder--item next-selection" v-if="type(filter)">
              <v-icon>chevron_right</v-icon>
              <component
                class="segment-builder--item next-selection _subtypes"
                :is="type(filter)"
                :isPinpoint="isPinpoint"
                :disabled="isReadOnlyRoleMixin"
                v-bind:filter="filter"
                v-bind:model.sync="filters[row]"
              />
            </div>
            <div class="segment-builder-buttons-div">
              <RfButton
                v-if="type(filter) === 'promos'"
                icon="mdi-content-copy"
                :disabled="isDisabledRoleMixin"
                @click="copyRow(row + 1)"
              />

              <RfButton
                icon="add_circle"
                color="primary"
                class="_add"
                :disabled="isDisabledRoleMixin"
                @click="addRow(row + 1)"
              />

              <RfButton
                icon="close"
                color="error"
                class="_remove"
                :disabled="isDisabledRoleMixin"
                @click="removeRow(row)"
              />
            </div>
          </div>
        </div>
        <div class="segment-builder-save-row">
          <RfButton
            icon="save"
            button-text="Save"
            color="success"
            :disabled="isDisabledRoleMixin || !dirty"
            style="width: 170px"
            @click="save"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEmpty, union, defaultTo } from "lodash-es";
import { mapActions, mapState } from "vuex";
import RfUser from "@/components/RfSegments/RfUser.vue";
import RfUsage from "@/components/RfSegments/RfUsage.vue";
import RfCustom from "@/components/RfSegments/RfCustom.vue";
import RfDevice from "@/components/RfSegments/RfDevice.vue";
import RfLocation from "@/components/RfSegments/RfLocation.vue";
import RfPromoInteractions from "@/components/RfSegments/RfPromoInteractions.vue";
import RfModalLoading from "@/views/RfModalLoading.vue";
import RoleMixin from "@/utils/RoleMixin";
import RfButton from "../buttons/RfButton.vue";
import {
  getUsageFilterData,
  customOrUsageFilters,
  getCustomFiltersData,
  isUserFilterDisabled,
} from "@/utils/segmentsHelpers";
import { UserFilterItems } from "@/utils/constants/SegmentsConstants";
import { getSegmentTypeDevices } from "@/utils/constants/DevicesConstants";
import RfUserBucket from "./RfUserBucket.vue";
import RfPinpointChannels from "./RfPinpointChannels.vue";
import CustomFieldsMixin from "@/utils/CustomFieldsMixin";

const pinpointAllowedFilters = ["custom", "channels"];
const fraudCheckAllowedFilter = ["user"];
const customSegmentAllowedFilters = [
  "usage",
  "user_bucket",
  "device",
  "location",
  "promos",
  "custom",
];

const getAllSegmentFilters = () => [
  { name: "Usage", value: "usage" },
  { name: "Users", value: "user" },
  { name: "User", value: "user_bucket" },
  { name: "Device", value: "device" },
  { name: "Location", value: "location" },
  { name: "Interactions", value: "promos" },
  { name: "Channel type", value: "channels" },
  { name: "Custom", value: "custom" },
];

export default {
  name: "RfSegmentBuilder",
  mixins: [RoleMixin, CustomFieldsMixin],
  props: ["segmentEditMode"],
  // key needs to match value in types to load comp dynamically
  components: {
    usage: RfUsage,
    user: RfUser,
    user_bucket: RfUserBucket,
    device: RfDevice,
    location: RfLocation,
    custom: RfCustom,
    channels: RfPinpointChannels,
    promos: RfPromoInteractions,
    RfModalLoading,
    RfButton,
  },
  data: () => ({
    upkey: 0,
    filters: [],
    filterOrLogics: [],
    dirty: false,
    loading: true,
    isUpdating: false,
    panel: null,
  }),
  computed: {
    ...mapState({ app: state => state.apps.currApp, segment: state => state.apps.currSegment }),
    isPinpoint() {
      return this.segment?.segment_type === "pinpoint";
    },
    notLocked() {
      return !this.segment.is_locked;
    },

    isFraudCheckOn() {
      return this.app.flags.fraud_check;
    },

    deviceFilters() {
      return this.segment.filter.device;
    },

    locationFilters() {
      return this.segment.filter.location;
    },

    userBucketFilters() {
      return this.segment.filter.user_bucket;
    },

    channelTypesFilter() {
      return this.segment.filter.connectors?.pinpoint?.channel_types;
    },

    userFilters() {
      return this.segment.filter.user;
    },

    userIdFilters() {
      return this.segment.filter.user_id;
    },

    promosFilters() {
      const promos = this.segment.custom_filter.promo_interaction || {};
      const values = promos.values || [];

      return values.reduce((h, filter) => {
        return {
          ...h,
          [filter.promo_slug]: filter,
        };
      }, {});
    },

    orGroupFilters() {
      return this.segment.filter.or_groups.map(group =>
        group
          .map(label =>
            this.filters.find(
              filter => this.type(filter) === "custom" && this.label(filter) === label,
            ),
          )
          .filter(Boolean),
      );
    },

    firstCustomFilterIndex() {
      return this.filters.findIndex(f => this.type(f) === "custom");
    },
  },

  watch: {
    segment() {
      this.loading = true;
      this.prepareFilters();
    },
    filters(newVal, oldVal) {
      this.dirty = !isEmpty(oldVal) && !this.loading;
    },
  },

  methods: {
    ...mapActions(["updateSegment"]),

    availableTypesFor(row) {
      const availableFilters = getAllSegmentFilters();
      const firstRowAcceptingCustom =
        this.firstCustomFilterIndex >= 0
          ? this.firstCustomFilterIndex - 1
          : this.filters.findLastIndex(f => !isEmpty(this.type(f)));
      if (row < firstRowAcceptingCustom) {
        availableFilters.find(({ value }) => value === "custom").disabled = true;
      }
      return availableFilters.filter(el =>
        this.isPinpoint
          ? pinpointAllowedFilters.includes(el.value)
          : (!this.isFraudCheckOn
              ? customSegmentAllowedFilters
              : customSegmentAllowedFilters.concat(fraudCheckAllowedFilter)
            ).includes(el.value),
      );
    },

    prepareFilters() {
      if (!this.segment) {
        this.filters = [{}];
        this.filterOrLogics = [];
        return;
      }

      this.filters = [];

      this.addFilters(
        "usage",
        customOrUsageFilters(getUsageFilterData(this.appCustomFields), this.segment.custom_filter),
      );
      this.addFilters("user", this.userFilters);
      this.addFilters("device", this.deviceFilters);
      this.addFilters("location", this.locationFilters);
      this.addFilters(
        "custom",
        customOrUsageFilters(
          getCustomFiltersData(this.appCustomFields),
          this.segment.custom_filter,
        ),
      );
      this.addFilters("promos", this.promosFilters);
      this.addFilters("user_bucket", this.userBucketFilters);
      this.addFilters("user_id", this.userIdFilters);
      this.addFilters("channels", this.channelTypesFilter);

      if (!this.filters?.length) this.filters = [{}];

      this.sortFiltersAndSetLogic();
      this.incrKey();
    },

    sortFiltersAndSetLogic() {
      const flattenedOrGroupFilters = [].concat(...this.orGroupFilters);
      // non-custom filters arranged by type, followed by custom non-orGroup filters
      const nonGroupFilters = this.filters
        .filter(filter => !flattenedOrGroupFilters.includes(filter))
        .sort((a, b) => {
          const typeA = this.type(a);
          const typeB = this.type(b);
          if (typeA !== "custom" && typeB === "custom") return -1;
          if (typeA === "custom" && typeB !== "custom") return 1;
          if (typeA < typeB) return -1;
          if (typeA > typeB) return 1;
        });
      const nonGroupLogics = nonGroupFilters.map(() => false);
      const orGroupLogics = this.orGroupFilters.map(group => group.map((_, i) => !!i));
      // orGroup filters at the end
      this.filters = [...nonGroupFilters, ...flattenedOrGroupFilters];
      this.filterOrLogics = [...nonGroupLogics, ...[].concat(...orGroupLogics)];
    },

    validDeviceFilter(data) {
      return Object.keys(getSegmentTypeDevices(this.app?.flags?.custom_devices)).includes(data);
    },

    validUsageFilter(data) {
      if (isEmpty(data)) return false;

      // usage struct is {options: {}, values: []}
      let options = data.options || {};
      options = [
        options.trend,
        options.frequency,
        options.range_type,
        options.time_period_days,
      ].some(Boolean);

      const values = !isEmpty(data.values);

      return options && values;
    },

    validUserBucketFilter(data) {
      if (isEmpty(data)) return false;
      if (Array.isArray(data) && data.filter(el => [null, "-INF", ""].includes(el)).length)
        return false;
      return true;
    },

    validCustomFilter(data) {
      return !isEmpty((data || {}).values);
    },

    validUserFilter(data) {
      const filter = UserFilterItems[data] || {};

      return this.isFraudCheckOn && !isUserFilterDisabled(filter.field, this.appSystemFields);
    },

    validPromoFilter(data) {
      const promo = data || {};

      const isValid = [
        promo.promo_slug,
        promo.matchTypeModel !== null, // needs to return true for true/false
        (promo.interaction_types || []).length,
      ];

      return isValid.every(Boolean);
    },

    // parse and add filter from db to filters array
    addFilters(type, conditions) {
      if (type === "user_bucket") {
        if (!this.validUserBucketFilter(conditions?.range)) return;
        this.filters.push({
          user_bucket: {
            user_bucket: { range: [...conditions.range], range_type: conditions.range_type },
          },
        });
      } else if (type === "user_id") {
        if (isEmpty(conditions?.values)) return;
        this.filters.push({
          user_bucket: {
            user_id: { values: [...conditions.values], negative_match: conditions.negative_match },
          },
        });
      } else if (type === "channels") {
        if (isEmpty(conditions)) return;
        this.filters.push({ channels: [...conditions] });
      } else {
        Object.keys(conditions || []).forEach(criteria => {
          const values = conditions[criteria];

          if (["location", "device", "user", "promos"].includes(type) && isEmpty(values)) return;
          if (type === "device" && !this.validDeviceFilter(criteria)) return;
          if (type === "custom" && !this.validCustomFilter(values)) return;
          if (type === "usage" && !this.validUsageFilter(values)) return;
          if (type === "user" && !this.validUserFilter(criteria)) return;

          this.filters.push({ [type]: { [criteria]: values } });
        });
      }
    },

    async save() {
      const segment = cloneDeep(this.segment);

      const data = {};
      const promos = new Set();
      let channels = [];

      this.filters.forEach(filter => {
        const type = this.type(filter);
        if (isEmpty(type)) return;

        const label = this.label(filter);
        let values = filter[type][label];

        // merge existing values
        if (["location", "device"].includes(type)) {
          const currentValues = defaultTo(data[type], {})[label];
          values = union(defaultTo(currentValues, []), values);
          values = values.filter(Boolean);
        }

        if (!data[type]) data[type] = {};

        if (type === "promos") {
          if (this.validPromoFilter(values)) promos.add(values);

          return;
        }
        if (filter.location_configs && filter.location_configs[label]) {
          if (!data.location_configs) data.location_configs = {};
          data.location_configs[label] = filter.location_configs[label];
        }

        if (type === "channels") {
          if (isEmpty(filter[type])) return;
          return (channels = filter[type]);
        }
        if (type === "user_bucket") {
          if (!data[label]) data[label] = {};
          if (label === "user_bucket") {
            data[label].range_type = filter[type][label].range_type;
            data[label].range = filter[type][label].range;
          }
          if (label === "user_id") {
            data[label].negative_match = filter[type][label].negative_match;
            data[label].values = filter[type][label].values;
          }
        } else {
          data[type][label] = values;
        }
      });

      if (promos.size) {
        data.promos = { promo_interaction: { values: [...promos] } };
      }

      segment.filter.device = data.device;
      segment.filter.location = data.location;
      segment.filter.user = data.user;
      segment.filter.user_id = data.user_id;
      segment.filter.user_bucket = data.user_bucket;
      segment.filter.location_configs = data.location_configs;
      segment.filter.connectors = {
        ...segment.filter?.connectors,
        pinpoint: { ...segment.filter.connectors?.pinpoint, channel_types: channels },
      };
      segment.custom_filter = { ...data.usage, ...data.custom, ...data.promos };
      segment.filter.or_groups = this.filterOrLogics
        .reduce((groups, orLogic, i) => {
          const label = this.label(this.filters[i]);
          if (orLogic) {
            groups[groups.length - 1].push(label);
          } else {
            groups.push([label]);
          }
          return groups;
        }, [])
        .filter(group => group.length > 1);

      this.isUpdating = true;
      await this.updateSegment({
        appId: this.$route.params.aid,
        segId: this.$route.params.sid,
        modelSegment: segment,
      });
      this.isUpdating = this.dirty = false;
    },

    type(filter) {
      return Object.keys(filter)[0];
    },

    label(filter) {
      const type = this.type(filter);
      return Object.keys(filter[type])[0];
    },

    canOrLogic(filter) {
      // Is a custom filter, but not the first (assumes sorted)
      return this.filters.filter(f => this.type(f) === "custom").indexOf(filter) > 0;
    },

    addRow(row, props = {}) {
      const { firstCustomFilterIndex } = this;
      if (firstCustomFilterIndex >= 0 && row > firstCustomFilterIndex) {
        props = { custom: {} };
      }
      this.filters.splice(row, 0, props);
      this.filterOrLogics.splice(row, 0, this.filterOrLogics[row - 1] ?? false);
      this.incrKey();
    },

    copyRow(row) {
      this.addRow(row, { ...this.filters[row] });
    },

    removeRow(row) {
      if (this.filters.length > 1) {
        this.filters.splice(row, 1);
        this.filterOrLogics.splice(row, 1);
      } else {
        this.filters = [{}];
        this.filterOrLogics = [];
      }

      this.sanitizeFilters();
      this.incrKey();
    },

    sanitizeFilters() {
      const { filters, firstCustomFilterIndex } = this;
      if (firstCustomFilterIndex >= 0) {
        filters.forEach((filter, i) => {
          if (i > firstCustomFilterIndex && this.type(filter) !== "custom")
            this.changeType("custom", i);
        });
      }
      this.filterOrLogics = this.filterOrLogics.map(
        (logic, i) => this.canOrLogic(filters[i]) && logic,
      );
    },

    changeType(type, row) {
      this.filters.splice(row, 1, { [type]: {} });
      this.filterOrLogics.splice(row, 1, false);
      this.sanitizeFilters();
      this.incrKey();
    },

    changeLogic(orLogic, row) {
      const filter = this.filters[row];
      if (orLogic && !this.canOrLogic(filter)) return;
      this.filterOrLogics[row] = orLogic;
      this.incrKey();
      this.dirty = true;
    },

    incrKey() {
      this.upkey += Math.random();
    },
  },

  async mounted() {
    const app = this.app || this.currApp || this.mixinApp;
    if (!this.appCustomFields.length)
      await Promise.all([
        this.getAppCustomFields({ appId: app.id, fieldType: "custom" }),
        this.getAppCustomFields({ appId: app.id, fieldType: "system" }),
      ]);
    this.prepareFilters();
  },

  updated() {
    this.loading = false;
  },
};
</script>
